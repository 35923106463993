import { t } from 'i18next';
import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import styles from './styles.module.scss';
import { Tags } from '../Tags';

export function SearchTags() {
  const [open, setOpen] = useState(false);
  return (
    <div className={ styles.container }>
      <a
        onClick={() => setOpen(!open)}
        className={`${styles.button} ${open ? styles.open : ''}`}
      >
        <span>
          {open ? t('debtBox.search.lessFilters') : t('debtBox.search.moreFilters')}
        </span>
        <FaChevronDown />
      </a>

      <div className={`${styles.content} ${!open && styles.hidden}`}>
        <Tags />
      </div>
    </div>
  );
}
