import { useState } from 'react';
import styles from './styles.module.scss';

type PillProps = {
  text: string;
  color?: string;
  selected: boolean;
  onClick: () => void;
};

export function Pill(props: PillProps) {
  const [selected, setSelected] = useState(props.selected);
  const handleOnClick = () => {
    setSelected(!selected);
    props.onClick();
  };

  return (
    <a
      onClick={handleOnClick}
      className={`${styles.container} ${selected ? styles.selected : ''}`}
      style={props.color ? selected ? { backgroundColor: props.color } : { borderColor: props.color } : {}}
    >
      <span
        className={styles.text}
        style={props.color ? selected ? {} : { color: props.color } : {}}
      >
        {props.text}
      </span>
    </a>
  );
}
