import { SearchTags } from '../../DebtBox/SearchTags';
import styles from './styles.module.scss';

export function SearchHeader() {
  return (
    <div className={ styles.container }>
      <SearchTags />
    </div>
  );
}
