import { t } from 'i18next';
import { Label } from '../../../../components/Label';
import { Pill } from '../../../../components/Pill';
import styles from './styles.module.scss';
import { TAGS } from './tags';

export function Tags() {
  return (
    <div className={ styles.container }>
      <Label text={t('debtBox.search.tags')} bold/>
      <div className={ styles.tags }>
        {
          TAGS.map((tag) => (
            <Pill
              text={tag.label}
              selected={false}
              color={tag.color} onClick={() => {}}
            />
          ))
        }
      </div>
    </div>
  );
}
