import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Skeleton } from '@mui/material';
import { ContributorSearch } from '../../../../@types/activeDebt/debtBox';
import styles from './styles.module.scss';
import { maskCpfOrCnpj } from '../../../../helpers/masks';

type ContributorsTabsType = {
  contributors: ContributorSearch[];
  onChange: (id: number) => void;
  loading?: boolean;
};

export function ContributorsTabs(props: ContributorsTabsType) {
  const [selected, setSelected] = useState<number>(props.contributors[0]?.id);

  useEffect(() => {
    if (props.contributors[0]) {
      setSelected(props.contributors[0].id);
      if (props.onChange) {
        props.onChange(props.contributors[0].id);
      }
    }
  }, [props.contributors]);

  const formatAddress = (contributor: ContributorSearch) => {
    return `
      ${contributor.street},
      ${contributor.street_number},
      ${contributor.address_complement},
      ${contributor.neighborhood} -
      ${contributor.city}`;
  };

  const handleClick = (id: number) => {
    setSelected(id);
    if (props.onChange) {
      props.onChange(id);
    }
  };

  if (props.loading) {
    return (
      <Skeleton height={120}/>
    );
  }

  return (
    <div className={ styles.container }>
      {
        props.contributors.map((contributor) => (
          <div className={`${styles.content} ${selected && styles.selected}`}>
            <a
              onClick={() => handleClick(contributor.id)}
              className={styles.button}
            >
              <h3 className={styles.name}>
                {contributor.name} - {maskCpfOrCnpj(contributor.document).mask()}
              </h3>
              <span className={styles.address}>
                <b>{t('debtBox.results.address')}</b>
                {formatAddress(contributor)}
              </span>
            </a>
          </div>
        ))
      }
    </div>
  );
}
