import Axios from '../config/apiDebitBox';

export default class DebtBoxService {
  public static async getContributorList(text: string) {
    return Axios.get(`api/autocomplete/active_debt/contributors?q[contributors_data_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getInscriptionsByProcessNumberList(text: string) {
    return Axios.get(`/api/autocomplete/active_debt/inscriptions?q[judicial_process_number_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getInscriptionsByNumberList(text: string) {
    return Axios.get(`/api/autocomplete/active_debt/inscriptions?q[administrative_inscription_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getInscriptionsByCdaNumberList(text: string) {
    return Axios.get(`/api/autocomplete/active_debt/inscriptions?q[cda_number_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getInscriptionsByInstallmentNumberList(text: string) {
    return Axios.get(`/api/autocomplete/active_debt/payment_requests?q[code_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getInscriptionsByKeyList(text: string) {
    return Axios.get(`/api/autocomplete/active_debt/inscriptions?q[sefin_key_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getContributors(query: string) {
    return Axios.get(`api/inscriptions/contributors?${query}`)
      .then((res) => res.data);
  }

  public static async getReceiptTypesByContributor(contributorId: number, inscriptions: number[]) {
    const inscriptionsEncode = [];
    for (const inscription of inscriptions) {
      inscriptionsEncode.push(`inscriptions_ids[]=${inscription}`);
    }
    return Axios.get(`api/inscriptions/receipt_types_by_contributor?contributor_id=${contributorId}&${inscriptionsEncode.join('&')}`)
      .then((res) => res.data);
  }
}
