export default {
  translations: {
    analytics: {
      steps: {
        tramit: 'andamento_tramitar',
        attach_document: 'anexar_documento',
        archive: 'arquivar_processo',
        sign: 'assinar_document',
      },
      new_citation_intimation: 'nova_citacao_intimacao',
      new_procedure: 'novo_processo',
      next_step: 'andamento',
      change_box: 'mudar_caixa',
      filter_box: 'filtro_caixa_processo',
      show_procedure_tab: 'clique_aba_detalhes_processo',
      open_document: 'abrir_arquivo',
      timeline: 'linha_tempo_processo',
    },
    home: {
      notifications: {
        title: 'Notificações',
        read: 'Marcar todos como lido',
        see_all: 'Ver histórico de notifcações',
        modal: {
          title: 'Histórico de Notificações',
        },
      },
      dashboard: {
        title: 'Dashboard',
        lastUpdated: 'Última atualização: {{date}}',
        lock: 'Por que estou bloqueado?',
        search: {
          placeholder: 'Pesquise...',
        },
        receiptTypeFilter: {
          placeholder: 'Todos os tributos',
        },
        areasFilter: {
          placeholder: 'Todas as áreas da PGM',
        },
        debt: {
          title: 'Dívida ativa',
          tooltip: 'Os gráficos mostram os valores totais do estoque da dívida ativa e a análise do desempenho por tipo de tributo.',
          goToBox: 'Acessar caixa de créditos',
          unauthorized: 'Dashboard de Dívida Ativa está bloqueado para seu tipo de acesso.',
        },
        digitalProcesses: {
          title: 'Processos digitais',
          tooltip: 'Os gráficos mostram a quantidade de processos por período e a análise do desempenho a partir da quantidade de entradas e saídas de processos.',
          goToBox: 'Acessar caixa de processos',
          unauthorized: 'Dashboard de Processos Digitais está bloqueado para seu tipo de acesso.',
          totals: {
            inbox: {
              title: 'Caixa de entrada',
              tooltip: 'Nº total de processos na minha caixa subtraído pelo Nº de saídas.',
            },
            outbox: {
              title: 'Saída',
              tooltip: 'Nº de processos que saíram da caixa de entrada hoje.',
            },
            inputbox: {
              title: 'Novos',
              tooltip: 'Nº de processos que chegaram da sua caixa de entrada hoje.',
            },
            title: 'Total de processos',
            tooltip: 'Os dados abaixo são referentes ao mês vigente do ano corrente.',
          },
          exportSpreadsheet: {
            title: 'Selecione o período',
            message: 'A planilha será gerada de acordo com o período da sua escolha.',
            toast: 'Planilha de andamento(s) "{{type}}" gerada com sucesso.',
            button: 'Exportar planilha',
            alert: {
              title: '...Gerando planilha de andamento',
              message: 'Aguarde até o download ser iniciado.',
            },
          },
        },
        actions: {
          export: 'Exportar',
        },
      },
      theme: {
        dark: 'Modo Escuro',
        light: 'Modo Claro',
      },
    },
    dashboard: {
      debtAmount: {
        title: 'Acervo da dívida ativa',
        info: 'O valor total do acervo é composto pela soma dos saldos devedores de todas as CDAs que estão ativas até a data atual.',
        tooltip: 'Os dados abaixo são referentes ao ano corrente.',
      },
      progressNegotiation: {
        title: 'Andamento dos créditos',
        tooltip: 'Distribuição do estoque de dívida ativa de acordo com cada fase da CDA.',
        start: 'Números de',
        end: 'em comparativo com o mesmo período de',
        period: 'ano do período:',
      },
      negotiations: {
        header: 'Arrecadação anual da Dívida Ativa no ano de',
        title: 'Negociação da dívida',
        total: 'Total de negociações',
        tooltip: 'Negociações iniciadas no ano corrente e análise de desempenho das negociações ativas.',
        paid: 'Total arrecadado no ano selecionado',
        lost: 'Negociações perdidas do ano corrente',
        active: 'Parcelamentos ativos (Promessas)',
        overdue: 'Negociações em atraso (Promessas)',
        totalInfo: 'O valor apresentado é a soma de todas as negociações ativas, independente de estarem atrasadas ou não.',
        activeInfo: 'O valor apresentado é a soma de todas as negociações ativas que não estão atrasadas.',
        overdueInfo: 'O valor apresentado é a soma de todas as negociações ativas que estão atrasadas.',
        lostInfo: 'O valor apresentado é a soma de todas as negociações perdidas/canceladas, durante o ano corrente.',
        period: 'Os dados abaixo são referentes ao ano corrente.',
      },
      prescriptions: {
        title: 'Prescrições',
        tooltip: 'Créditos extintos por ultrapassar o tempo de pagamento e atingir valor superior à R$ 6.000,00.',
        toPrescribe: 'A prescrever',
        toPrescribeTooltip: 'Previsão do valor total de créditos a prescrever no mês.',
        wouldPrescribe: 'Iria prescrever',
        prescribedUntil: 'Prescrito até ',
        prescribed: 'Prescrito',
        prescribedTooltip: 'Total de créditos que foram prescritos no mês.',
        period: 'A prescrever (entre hoje até o fim de {{current_year}}): ',
        current: 'Referente ao ano atual',
        tooltipToPrescribe: `A prescrever: o valor mensal ${'a prescrever'} é atualizado até o dia primeiro do mês correspondente.`,
        tooltipPrescribed: 'Prescritos: no mês corrente o valor de créditos prescritos são atualizados diariamente.',
        months: {
          1: 'Jan',
          2: 'Fev',
          3: 'Mar',
          4: 'Abr',
          5: 'Mai',
          6: 'Jun',
          7: 'Jul',
          8: 'Ago',
          9: 'Set',
          10: 'Out',
          11: 'Nov',
          12: 'Dez',
        },
      },
      digitalProcesses: {
        seeArea: 'Ver área ({{length}} colaborador{{plural}})',
        totals: {
          inbox: 'Caixa de entrada',
          outbox: 'Saída',
          inputbox: 'Novos',
          title: 'Total de processos',
          tooltip: 'Os dados abaixo são referentes ao mês vigente do ano corrente.',
        },
        processLegend: {
          unread: {
            title: 'Processos não lidos',
            description: 'Total de processos que não foram visualizados ainda pelo(s) responsável (is) atual (is).',
          },
          exit: {
            title: 'Saída de processos',
            description: 'Total de processos que foram arquivados',
            descriptionField: 'Total de processos que saíram da área por meio de tramitação ou arquivamento',
            descriptionIndividual: 'Total de processos que saíram da caixa de entrada do responsável atual, por tramitação ou arquivamento.',
          },
          new: {
            title: 'Novos processos',
            description: 'Total de processos que foram criados/cadastrados por todas as áreas',
            descriptionField: 'Total de processos que foram criados ou que entraram na área',
            descriptionIndividual: 'Total de processos que foram criados pelo responsável atual (que não saíram de sua caixa) e que entraram na sua caixa de entrada por meio de tramitação ou distribuição',
          },
          total: {
            title: 'Total de processos',
            description: 'Total de processos que estão ativos na PGM',
            descriptionField: 'Total de processos que estão ativos e com usuários da área',
            descriptionIndividual: 'Total de processos que estão na caixa de entrada do responsável atual',
          },
        },
        chart: {
          new: 'novos: {{value}}',
          not_read: 'não lidos: {{value}}',
          exit: 'saída: {{value}}',
        },
        performance: {
          title: 'Gráfico de desempenho',
          tooltip: 'Quantidade mensal de saídas, entradas e o total de processos, de forma progressiva.',
          total_inbox: 'total',
          total_outbox: 'saída',
          total_inputbox: 'novos',
          total_snapshot: 'estoque',
        },
        steps: {
          title: 'Gráfico de andamentos',
          tooltip: 'Quantidade de andamentos realizados nos processos.',
          total: 'Total',
        },
        productivityChart: {
          title: 'Colaboradores',
          tooltip: 'Quantidade mensal de saídas, entradas e o total de processos por cada colaborador.',
          dateTooltip: 'Os dados abaixo são referentes ao mês vigente do ano corrente',
          totalInbox: 'total:',
          totalInputbox: 'novos:',
          totalOutbox: 'saída:',
          placeholder: 'Busca por colaborador...',
          placeholderProcess: 'Buscar por processos',
        },
        searchField: {
          placeholder: 'Selecione uma área ou colaborador',
        },
      },
      month: {
        jan: 'Janeiro',
        feb: 'Fevereiro',
        mar: 'Março',
        apr: 'Abril',
        may: 'Maio',
        jun: 'Junho',
        jul: 'Julho',
        aug: 'Agosto',
        sep: 'Setembro',
        oct: 'Outubro',
        nov: 'Novembro',
        dec: 'Dezembro',
      },
      state: {
        total_requirement_requested: 'Requerimento solicitado',
        total_requirement_approved: 'Requerimento aprovado',
        total_inscript: 'Inscrito em dívida',
        sent_protest: 'Protestado',
        legal_action: 'Ajuizado',
        canceled: 'Cancelado',
        prescribed: 'Prescrito',
        settled: 'Quitado',
        redeemed: 'Remido',
      },
      general: {
        untilToday: 'até hoje',
      },
    },

    procedureCollections: {
      title: 'Acervo de processos da PGM',
      procedureList: {
        description: 'Descrição',
        data: 'Parte interessadas',
        location: 'Localização',
        subject: '<strong>Assunto:</strong> {{subject}}',
        interested_parts: '{{qnt}} parte{{plural}}',
        flux: '<strong>Fluxo:</strong> {{flux}}',
        origin_procedure_name: '<strong>Origem do processo:</strong> {{origin}}',
        created_at: '<strong>Criado em:</strong> {{date}}',
        procedure_value: '<strong>Valor:</strong> {{value}}',
        audiences: '<strong>Audiências:</strong> {{value}}',
        received_date: '<strong>Recebimento:</strong> {{value}}',
        lastStep: '<strong>Último andamento:</strong> {{value}}',
        time_inbox: '<strong>Tempo na caixa:</strong> {{value}}',
        involved_people: '<strong>Responsáveis anteriores:</strong>',
        individual_name: '<strong>Responsável atual:</strong>',
        resume: '<strong>Resumo</strong>',
        empty: {
          title: '<bold> Busque por processos </bold> em todo o acervo da Procuradoria Geral Do Município de Fortaleza',
          labelOne: 'Digite na busca rápida a informação do processo que você procura.',
          labelTwo: 'Para uma busca mais refinada, clique no botão <i></i> e use mais de um campo.',
          labelThree: 'Dúvidas sobre o acervo de processos? <a>Saiba mais aqui.</a>',
          resume: 'Sem resumo',
        },
        restricted_access: 'Acesso restrito para usuários da PGM',
      },
      notHasAccessProcess: {
        title: '<strong>O processo acessado é de acesso restrito</strong> e, portanto, não está disponível para a sua visualização.',
        subtitle: 'Caso necessite de mais informações ou tenha dúvidas, por favor, entre em contato com o <a href="https://wa.me/558589260327" target="_blank">servicedesk</a>',
      },
      procedureCollection: {
        LastStep: 'Último andamento',
        InvolvedPeoples: 'Pessoas envolvidas',
      },
      redirect: 'Você foi redirecionado para o Acervo de Processos da PGM, pois não foram encontrados os processos desejados na sua caixa de entrada. O resultado para a sua pesquisa foi de {{total}} processo(s).',
    },

    schedule: {
      title: 'Agenda',

      filters: {
        box: {
          title: 'Em qual caixa de processos?',
          all: 'Todo o acervo',
          mySchedule: 'Minha agenda',
          external: 'Agenda externa',
          search: 'Pesquisar',
          reason: {
            title: 'Motivos?',
            placeholder: 'Selecione um motivo',
          },
        },
      },

      box: {
        allCollection: 'Todo o acervo',
        myBox: 'Minha caixa',
        externalBox: 'Caixas externas',
      },

      types: {
        audience: 'Audiência',
        intimation: 'Intimação',
        citation: 'Citação',
        attach_documentation: 'Anexar de documentação',
        dispatch: 'Despacho',
        petition: 'Petição',
      },

      deadline: {
        checkbox: 'Marcar como concluído',
        details: 'Detalhes {{date}}',
        title: 'Título:',
        description: 'Descrição:',
        procedure_number: 'N° do processo:',
        attorney_name: 'Procurador:',
        court_name: 'Vara:',
        deadline: 'Data e Hora:',
        individual_name: 'Responsável atual:',
      },

      destroy: {
        warning: 'Deseja realmente excluir esse prazo?',
      },
    },

    documentSearch: {
      title: 'Busca documental (Beta)',
      filter: 'Buscar',
      clear: 'Limpar filtro',
      relevance: 'Relevância',
      searchBar: {
        placeholder: ' Pesquise por palavras-chave',
        filters: {
          main_field: {
            label: 'Área atual',
            tooltip: 'Busque pela área atual do processo.',
            placeholder: 'Todas as áreas',
          },
          subject: {
            label: 'Assunto',
            tooltip: 'Busque pelo assunto do processo.',
            placeholder: 'Todos os assuntos',
          },
          flux_name: {
            label: 'Fluxo',
            tooltip: 'Fluxos são os tipos de processos. Busque por um fluxo específico.',
            placeholder: 'Todos os fluxos',
          },
          document_type: {
            label: 'Tipo de documento',
            tooltip: 'Busque por um tipo de documento específico.',
            placeholder: 'Todos os tipos',
            no_type: 'Sem tipo',
          },
        },
      },
      empty: {
        title: '<bold> Busque por documentos </bold> em todo o acervo da Procuradoria Geral Do Município de Fortaleza',
        labelOne: 'Digite palavras-chave que deseje achar nos documentos processuais do acervo da PGM.',
        labelTwo: '<bold>Dica de busca:</bold> Utilize os operadores <bold>E</bold> , <bold>OU</bold> , <bold>~</bold> , <bold>“”</bold> e <bold>()</bold> para melhorar o comando da busca. Para uma busca mais refinada, clique no botão <i></i> e use mais de um campo.',
        labelThree: 'Dúvidas sobre a Busca documental? <a>Saiba mais aqui.</a>',
      },
      noResults: {
        label: 'Repense o conteúdo aplicado na busca.<br /> Utilize os operadores <bold>E</bold> , <bold>OU</bold> , <bold>~</bold> , <bold>“”</bold> e <bold>()</bold> para melhorar o comando da busca. Experimente os filtros avançados para refinar o seu resultado.',
        link: 'Saiba mais clicando aqui',
      },
      documents: {
        item: {
          title: 'Título:',
          created_by: 'Documento elaborado por: ',
          located: 'Localizado no {{fluxName}} de número:',
          document_type: 'Tipo de documento: ',
          flux: 'Fluxo:',
          subject: 'Assunto:',
          process_number: 'Processo:',
        },
        back_top: 'Voltar ao topo',
      },
    },

    procedureBox: {
      title: 'Minha caixa de processos',

      editProcedure: {
        submit: 'Editar processo',
        toasts: {
          success: 'Sucesso ao editar processo.',
        },
      },

      customizeColumns: {
        title: 'Escolha os dados que deseja ver na sua caixa de processos',
        action: 'Personalizar colunas',
        procedureNumber: 'N° 00000000-00.00000.0.00.0000',
        toast: 'Sucesso ao alterar a ordem dos dados.',
        select: {
          placeholder: 'Selecione um dado',
          options: {
            subject: 'Assunto',
            flux: 'Fluxo',
            procedure_value: 'Valor',
            last_product_generated: 'Último Andamento',
            origin_procedure_name: 'Origem do processo',
            process_category_name: 'Classificação',
            hearings_count: 'Audiências',
            competence: 'Competência',
            cdas_linked: 'CDAS Vinculadas',
            time_inbox: 'Tempo na caixa',
            created_at: 'Criado em',
            received_date: 'Recebimento',
            inactive_time: 'Tempo Inativo',
            requiriment_date: 'Data de requerimento',
            interested_parts: 'Parte Interessada',
            current_responsible: 'Responsável Atual',
            previous_responsibles: 'Responsáveis Anteriores',
            shared_with: 'Compartilhado com',
            deadlines: 'Prazos',
          },
        },
        button: 'Salvar',
      },

      newProcedure: {
        button: 'Novo cadastro',
        submit: 'Cadastrar processo',
        edit: 'Editar processo',
        newProcedure: 'Novo processo',
        citationIntimation: {
          title: 'Nova citação/intimação',
          form: {
            type: 'Selecione um tipo',
            processNumber: {
              label: 'Número de processo',
              placeholder: 'Digite o número de processo',
            },
            subject: 'Selecione um assunto',
            competence: 'Selecione uma competência',
            exercise: 'Exercício',
            content: 'Teor da citação/intimação',
            observation: 'Observações',
            submit: 'Cadastrar',
          },
        },
        selectFlux: {
          label: 'Fluxo do processo',
          placeholder: 'Selecione o fluxo',
        },
        toasts: {
          success: 'Sucesso ao cadastrar novo processo.',
          edit: 'Sucesso ao editar processo.',
          successCitation: 'Sucesso ao cadastrar nova citação/intimação.',
          error: 'Erro ao cadastrar novo processo.',
          showProcess: 'Ver processo',
        },
        data: 'Característica',
        processNumber: '{{fluxName}} nº {{number}}',
        interestedParts: {
          header: 'Parte interessada',
          form: {
            type: 'Tipo',
            name: {
              label: 'Nome',
              placeholder: 'Nome pessoa física',
            },
            nameCnpj: {
              label: 'Razão social',
              placeholder: 'Nome pessoa jurídica',
            },
            uniq: {
              label: 'Parte interessada',
            },
            passive: {
              label: 'Parte passiva',
              placeholder: 'Selecione a parte',
            },
            active: {
              label: 'Parte ativa',
              placeholder: 'Selecione a parte',
            },
            participation_id: '',
            cpf: {
              label: 'CPF',
              placeholder: '000.000.000-00',
            },
            gender: {
              label: 'Sexo',
              placeholder: 'Sexo',
            },
            cnpj: {
              label: 'CNPJ',
              placeholder: '00.000.000/0000-00',
            },
            rg: {
              label: 'RG',
              placeholder: '00000000000',
            },
            cep: {
              label: 'CEP',
              placeholder: '00.000-000',
            },
            city: {
              label: 'Município',
              placeholder: 'Nome do municíopio',
            },
            address: {
              label: 'Logradouro',
              placeholder: 'Nome da rua',
            },
            complement: {
              label: 'Complemento',
              placeholder: 'Complemento',
            },
            neighborhood: {
              label: 'Bairro',
              placeholder: 'Nome do bairro',
            },
            address_number: {
              label: 'Número',
              placeholder: '0000',
            },
            issuing_authority: {
              label: 'Órgão emissor',
              placeholder: 'Órgão emissor',
            },
            noAddressNumber: {
              label: 'Sem Número',
            },
            noDocument: {
              label: 'Sem Identificação',
            },
          },
        },
        newProcedureRegistration: {
          form: {
            characteristcs: 'Características',
            data: 'Dados',
            processNumber: {
              label: 'Número do processo',
              placeholder: '0000000000',
            },
            spu_number: {
              label: 'Número de SPU',
              placeholder: '00000',
            },
            iterested_part_cpf_cnpj: {
              label: 'Parte interessada - CPF/CNPJ',
              error: 'CPF ou CNPJ inválido',
            },
            interested_part_name: {
              label: 'Parte interessada - Nome',
            },
            origin_procedure_id: {
              label: 'Origem do processo',
            },
            process_type: {
              label: 'Tipo',
            },
            subject_id: {
              label: 'Assunto',
              new: 'Cadastrar assunto',
            },
            date_receipt: {
              label: 'Data do recebimento',
            },
            deadline_procedure: {
              label: 'Prazo',
            },
            resume: {
              label: 'Resumo',
            },
          },
        },
        newRequestedLegalAction: {
          form: {
            data: 'Dados',
            contributors: {
              title: 'Contribuintes',
              placeholder: 'Selecione um contribuinte',
              tooltip: 'Para prosseguir escolha um contribuinte',
            },
            contributor: 'Nome',
            id: '',
            document: 'Documento',
            receipt_type: 'Tipo Receita',
            next_prescription: 'Próxima prescrição',
            filter_inscriptions: 'Inscrições do Filtro',
            filter_balance: 'Saldo do Filtro',
            conglobated_inscriptions: 'Inscrições Conglobadas',
            conglobated_balance: 'Saldo Conglobado',
            all_inscriptions: 'Total de Inscrições',
            total_balance: 'Saldo Total',
            cda_requirement: 'CDA / Requerimento',
            balance: 'Saldo Devedor',
            prescription_date: 'Prescrição',
            charge: 'Cobrança Bancária',
            protested_date: 'Protesto',
            contributorError: 'Sem inscrições para ajuizamento',
            contributorMessage: 'Carregando tabela de inscrições...',
          },
        },
        newConsultancyProcess: {
          form: {
            data: 'Dados',
            processNumber: {
              label: 'Número do processo',
              placeholder: '0000000000',
            },
            interested_part: {
              label: 'Parte interessada',
              placeholder: 'Digite o nome da parte interessada',
            },
            consultancy_process_origin_id: {
              label: 'Origem do processo',
              placeholder: 'Selecione a origem do processo',
              new: 'Cadastrar Origem',
              success: 'Origem criada com sucesso',
            },
            consultancy_process_subject_id: {
              label: 'Assunto',
              placeholder: 'Selecione o assunto do processo',
              new: 'Cadastrar Assunto',
              success: 'Assunto criado com sucesso',
            },
            open_date: {
              label: 'Data de abertura',
            },
            phone_number: {
              label: 'Telefone de contato',
              placeholder: 'Digite o número de telefone de contato',
            },
            cpf_cnpj: {
              label: 'CPF/CNPJ da parte interessada',
              placeholder: 'Digite o CPF ou CNPJ da parte interessada',
            },
          },
        },
        newOfficialLetterSefin: {
          form: {
            data: 'Dados',
            officialNumber: {
              label: 'Número do ofício *',
            },
            interestedPart: {
              label: 'Parte interessada *',
            },
            referenceProc: {
              label: 'Ref. Proc',
            },
            official_letter_sefin_subject: {
              label: 'Assunto *',
            },
          },
        },
        newAdministrativeProurmaProcess: {
          form: {
            data: 'Dados',
            address: 'Endereço',
            process_number: {
              label: 'Número do processo no SPU',
              placeholder: '00000000',
            },
            origin_procedure_id: {
              label: 'Origem do processo',
            },
            received_at: {
              label: 'Data do recebimento',
            },
            spu_subject_id: {
              label: 'Assunto no SPU',
            },
            subject_id: {
              label: 'Assunto da Prourma',
            },
            observation: {
              label: 'Observação',
            },
            cep: {
              label: 'CEP',
            },
            street_type: {
              label: 'Tipo de logradouro',
            },
            street: {
              label: 'Descrição do logradouro',
            },
            address_number: {
              label: 'Número',
            },
            allotment: {
              label: 'Nome do loteamento',
            },
            city_area: {
              label: 'Regional',
            },
            complement: {
              label: 'Complemento',
            },
            reference: {
              label: 'Referência',
            },
          },
        },
        newProurmaDeed: {
          form: {
            data: 'Dados',
            registry: {
              label: 'Cartório',
            },
            livro: {
              label: 'Livro',
            },
            folha: {
              label: 'Folha',
            },
            acquisition_type: {
              label: 'Tipo de Escritura',
            },
            ordinance: {
              label: 'Decreto Municipal',
            },
            interested_part: {
              label: 'Parte interessada',
            },
            interested_part_document: {
              label: 'CPF/CNPJ da parte',
            },
            goal: {
              label: 'Finalidade',
            },
            street_type: {
              label: 'Logradouro',
            },
            street: {
              label: 'Localização',
            },
            deed_signed_at: {
              label: 'Data de Assinatura da Escritura',
            },
          },
        },
        newIntimation: {
          form: {
            data: 'Dados',
            processNumber: {
              label: 'Número do processo',
              placeholder: '0000000000',
            },
            exercise: {
              label: 'Exercício',
              placeholder: '0000',
            },
            competition_id: {
              label: 'Competência',
              placeholder: 'Seleciona uma competência',
            },
            observation: {
              label: 'Observação',
              placeholder: 'Digite uma observação',
            },
            description: {
              label: 'Teor da citação/intimação',
              placeholder: 'Digite o teor da citação/intimação',
            },
            subject_title_id: {
              label: 'Selecione um assunto',
              placeholder: 'Selecione um assunto',
            },
            process_type: {
              label: 'Selecione um tipo',
            },
          },
        },
        newPetitioningInformations: {
          existingProcess: 'processo já cadastrado <a target="_blank" href="/procedure_box/show_procedure/{{procedureId}}">clique aqui</a> para acompanhar',
          form: {
            data: 'Dados',
            cdas: {
              title: 'CDA',
              number: {
                label: 'Número da CDA',
                placeholder: '000000000',
              },
              date: {
                label: 'Data',
              },
              currency: {
                label: 'Moeda',
                placeholder: 'Selecione a moeda',
              },
              value: {
                label: 'Valor',
                placeholder: 'R$ 0,00',
              },
              cda_tax_type_id: {
                label: 'Tipo de tributo',
                placeholder: 'Selecione o tipo de tributo',
              },
              error: 'CDA não pode estar vazio',
            },
            processNumber: {
              label: 'Número do processo',
              placeholder: '00000000000',
            },
            receipt_date: {
              label: 'Data de recebimento',
              placeholder: '',
            },
            class_denomination_id: {
              label: 'Classe',
              placeholder: 'Selecione a classe',
            },
            origin_procedure_id: {
              label: 'Origem',
              placeholder: 'Selecione a origem',
            },
            subject_id: {
              label: 'Assunto',
              placeholder: 'Selecione o assunto',
            },
            active_part_type: {
              label: 'Tipo',
              placeholder: 'Selecione o tipo',
            },
            active_part_name: {
              label: 'Nome',
              placeholder: 'Digite o nome',
            },
            active_part_document: {
              label: 'Documento',
              placeholder: '00000000000',
            },
            passive_part_type: {
              label: 'Tipo',
              placeholder: 'Selecione o tipo',
            },
            passive_part_name: {
              label: 'Nome',
              placeholder: 'Digite o nome',
            },
            passive_part_document: {
              label: 'Documento',
              placeholder: '00000000000',
            },
            cda_tax_type_id: {
              label: 'Tipo de tributo',
              placeholder: 'Selecione o tipo',
            },
            partActive: {
              title: 'Parte ativa',
              defaultType: 'Exequente',
              defaultName: 'Fazenda Pública do Município de Fortaleza',
            },
            partPassive: {
              title: 'Parte passiva',
              defaultType: 'Exequido',
            },
            observation: {
              label: 'Observações',
              placeholder: 'Digite uma observação',
            },
          },
        },
        newSuspensionCancellationRequest: {
          form: {
            data: 'Dados',
            contributor: {
              label: 'Nome do contribuinte',
              placeholder: 'Selecione o contribuinte',
            },
            administrativeInscription: {
              label: 'Inscrição Administrativa',
              placeholder: 'Digita a inscrição administrativa',
            },
            cdaNumber: {
              label: 'Número de CDA',
              placeholder: 'Digite o número de CDA',
            },
            originKey: {
              label: 'Chave de origem',
              placeholder: 'Digite a chave de origem',
            },
            processNumber: {
              label: 'Número de processo',
              placeholder: 'Digite o número de processo',
            },
            reason: {
              label: 'Motivo',
              placeholder: 'Selecione o motivo',
            },
            processType: 'Tipo de processo',
            table: {
              administrative_inscription: 'Inscrição Administrativa',
              receipt_type_description: 'Tipo de Receita',
              cda_number: 'CDA',
              state: 'Fase',
              fiscal_date: 'Exercício',
              sefin_key: 'Chave de Origem',
              balance: 'Valor',
            },
          },
        },
        newJudicialProcess: {
          data: 'Dados',
          event_attributes: {
            title: 'Audiência',
            event_type: {
              label: 'Tipo de evento',
              placeholder: 'Selecione o tipo de evento',
            },
            process_number: {
              label: 'Número do processo',
              placeholder: '0000000000',
            },
            court_id: {
              label: 'Vara',
              placeholder: 'Selecione a vara',
            },
            individual_id: {
              label: 'Procurador',
              placeholder: 'Selecione o procurador',
            },
            event_schedule: {
              label: 'Horário da audiência',
              placeholder: '00/00/0000 às 00:00',
            },
          },
          form: {
            process_number: {
              label: 'Número do processo',
              placeholder: '0000000000',
              processExist: 'Número processo já existe',
            },
            origin_id: {
              label: 'Origem',
              placeholder: 'Selecione a origem',
              new: 'Cadastrar origem',
            },
            attorney_id: {
              label: 'Procurador(a)',
              placeholder: 'Selecione o(a) procurador(a)',
            },
            competence_id: {
              label: 'Competência',
              placeholder: 'Selecione a competência',
            },
            class_name_id: {
              label: 'Classe',
              placeholder: 'Selecione a classe',
            },
            major_subject_id: {
              label: 'Assunto',
              placeholder: 'Selecione o assunto',
            },
            subject: {
              label: 'Tipo de assunto',
              placeholder: 'Selecione o tipo de assunto',
            },
            share_price: {
              label: 'Valor da ação',
              placeholder: 'R$ 0,00',
            },
          },
        },
        newPJARecord: {
          form: {
            data: 'Dados',
            processNumber: {
              label: 'Número do processo',
              placeholder: '0000000000',
            },
            cpf: {
              label: 'CPF',
              placeholder: '000.000.000-00',
            },
            job_title: {
              label: 'Cargo',
              placeholder: 'Nome do cargo',
            },
            enrollment: {
              label: 'Matrícula',
              placeholder: '00000',
            },
            enrollment_extra: {
              label: 'Matrícula secundária',
              placeholder: '00000',
            },
            phone_number: {
              label: 'Telefone de contato',
              placeholder: '(99) 99999-9999',
            },
            interested_part: {
              label: 'Parte interessada',
              placeholder: 'Nome da parte interessada',
            },
            origin: {
              label: 'Origem',
              placeholder: '...',
            },
            subject: {
              label: 'Assunto',
              placeholder: 'Selecione o assunto',
            },
            requirement_date: {
              label: 'Data do requerimento',
            },
          },
        },
      },

      ownership: {
        title: 'Alteração de titularidade',
        table: {
          currentContributor: 'Informações do titular atual',
          newContributor: 'Dados do novo titular',
          inscriptions: 'CDAs',
        },
        buttons: {
          approve_requirement: 'Deferir',
          reject_requirement: 'Indeferir',
        },
        modal: {
          authorized: 'Deseja realmente <b>Deferir</b> a alteração de titularidade dos créditos indicados',
          rejected: 'Deseja realmente <b>Indeferir</b> a alteração de titularidade dos créditos indicados',
        },
        messages: {
          authorized: {
            withoutIndividual: 'Deferido',
            withIndividual: 'Deferido em {{date}} por {{name}}',
          },
          rejected: {
            withoutIndividual: 'Indeferido',
            withIndividual: 'Indeferido em {{date}} por {{name}}',
          },
        },
      },

      processExtract: {
        title: 'Extrato de processo',
        progress: 'Andamentos',
        processNumber: '{{fluxName}} nº {{number}}',

        tabs: {
          data: 'Dados',
          documents: 'Documentos',
          schedule: 'Agenda',
          withMe: 'Com a mesma parte{{samePartLength}}',
          cdas: 'CDAs associadas ({{cdas}})',
          pjeExtract: 'Extrato PJE',
        },

        timeline: {
          title: 'Linha do tempo',
          error: 'Erro ao buscar os dados',
        },

        cdas: {
          total: 'Valor total',
          associateButton: 'Associar CDA(s)',
          extract: 'Gerar extrato',
          resend: 'Reenviar lançamento',
          cda: 'Gerar CDA(s)',
          dropdown: 'Ações',
          alert: {
            title: 'Deseja baixar e anexar o documento da CDA no processo?',
            text: 'O arquivo da CDA irá ficar na aba documentos do processo.',
            confirmText: 'Apenas baixar',
            cancelText: 'Anexar e baixar',
          },
          loading: {
            title: 'Aguarde...',
            text: 'Estamos processando sua solicitação.',
          },
          toasts: {
            successGenerate: 'CDA(s) gerada(s) com sucesso',
            successAttach: 'CDA(s) anexadas(s) com sucesso',
            error: 'Ocorreu um erro durante a solicitação.',
          },
          table: {
            contributor: 'Contribuinte',
            number: 'Número da CDA',
            step: 'Fase atual',
            lastChange: 'Data da fase atual',
            prescriptionDate: 'Data da prescrição',
            value: 'Saldo devedor',
            associatedBy: 'Associado por',
            removeAssociation: 'Remover associação',
          },
          associate: {
            title: 'Insira o número da(s) CDA(s) ao qual deseja associar ao processo:',
            placeholder: 'Digite 3 ou mais caracteres',
            button: 'Associar',
          },
        },

        documentList: {
          digitalFolder: 'Pasta Digital',
          inElaboration: 'Em elaboração',
          finished: 'Concluídos',
          notFoundDocumentList: 'Não há documentos anexados ao processo.',
          button: {
            text: 'Excluir arquivos',
            downloadText: 'Baixar documentos',
            moveDocumentsInBulk: 'Mover para Concluídos',
          },
        },

        extractDataTable: {
          currentResponsbile: 'Responsável atual',
          previousResponsible: 'Responsáveis anteriores',
          sharedWith: 'Compartilhado com',
          processNumber: 'Número do processo',
          processFlux: 'Fluxo do processo',
          processOrigin: 'Origem do processo',
          lastStep: 'Último andamento',
          processStatus: 'Estado do processo',
          currentArea: 'Área atual',
          subProcessGenereted: 'Subprocessos gerados',
          noSubProcedures: 'Não há subprocessos',
          mainSubProcedure: 'Processo principal do subprocesso',
          noMainSubProcedure: 'Não há processo principal do subprocesso',
          classification: 'Classificação',
          interessedPart: 'Parte interessada',
          interessedPartReduce: '{{firstInteressedPart}} e outros {{count}}',
          deadlines: 'Prazos',
          subject: 'Assunto',
          annotations: 'Anotações',
          noSubject: 'Nenhum assunto cadastrado',
          noAnnotations: 'Nenhuma nota adicionada',
          seeAnnotation: '{{amount}} anotação',
          seeAnnotations: '{{amount}} anotações',
          attachedProcedures: {
            title: 'Processos apensos',
            none: 'Nenhum processo apenso.',
          },
          processStatusType: {
            start: 'Iniciado',
            other: 'Em Andamento',
            finish: 'Finalizado',
            start_finish: 'Iniciado/Finalizado',
            archived: 'Arquivado',
          },
        },

        samePart: {
          yes: 'Sim',
          no: 'Não',
          title: 'Processos com a mesma parte interessada:',
          noData: 'Não foram encontrados outros processos com esta mesma parte',
          process: 'Processo',
          mainField: {
            label: 'Área Cadastrante',
            placeholder: 'Todas as Áreas',
          },
          flux: {
            label: 'Fluxo',
            placeholder: 'Todos os Fluxos',
          },
          order: {
            label: 'Ordenar por: ',
            placeholder: 'Última Verificação',
          },
          classification: 'Classificação',
          lastVerification: 'Última verificação',
          verifiedBy: 'Verificado por',
          listOrJudged: 'Litispendência/coisa julgada/mesmo objeto?',
        },

        pjeExtract: {
          petition: 'Petição',
          consulting: 'Consultando os dados do processo no PJE, por favor aguarde alguns instantes...',
          lastUpdated: '<span>Dados Atualizados:</span> a consulta foi realizada com sucesso, dia {{date}}',
          basicData: {
            title: 'Dados do processo',
            subject: 'Assunto',
            situation: 'Situação do processo',
            classProcedural: 'Classe processual',
            competence: 'Competência',
            judgingOrgan: 'Órgão julgador',
            actingMagistrate: 'Magistrado atuante',
            localityCode: 'Código de localidade',
            judgeDate: 'Data do ajuizamento',
            levelSecrecy: 'Nivel de sigilo',
            mpintervention: 'Intervenção do MP?',
            attachments: 'Anexos do processo',
            lastMove: 'Última movimentação',
            warningHistory: 'Histórico de Avisos',
            transactionHistory: 'Histórico de movimentações',
            withIntervention: 'Com intervenção',
            withoutIntervention: 'Sem intervenção',
            attachmentsCount: '{{count}} documentos',
            movementsCount: '{{count}} movimentações',
            noticesCount: '{{count}} avisos',

            table: {
              activePart: 'Parte ativa',
              passivePart: 'Parte passiva',
              name: 'Nome',
              representativeType: 'Tipo representante',
              dateBirth: 'Data de nascimento',
              documentNumber: 'N° do Documento',
              personType: 'Tipo de pessoa',
              documentIssuer: 'Emissor do documento',
              documentType: 'Tipo de Documento',
              address: {
                short: 'Endereço',
                full: 'Endereço completo',
                zipCode: 'CEP',
                publicPlace: 'Logradouro',
                number: 'Número',
                complement: 'Complemento',
                neighborhood: 'Bairro',
                city: 'Cidade',
                state: 'Estado',
              },
            },

            buttons: {
              notices: 'Avisos',
              movements: 'Movimentos',
              digitalFolder: 'Pasta Digital do PJE',
              update: 'Atualizar',
            },
          },

          notice: {
            procedureInfo: 'Processo de N <b>{{number}}</b> do fluxo Processo de {{flux}} selecionado',
            attention: {
              title: 'Atenção!',
              text: 'Segue abaixo a lista de todos os <b>avisos pendentes que estão aguardando análise</b> do teor da comunicação. Ao clicar em <b>visualizar,</b> o sistema solicita as informações do aviso para o PJE e ele retorna as informações <bDanger>iniciando assim o prazo referente ao aviso</bDanger>.',
            },
            warning: 'Avisos',
            table: {
              code: 'Código do aviso',
              typeCommunication: 'Tipo de comunicação',
              date: 'Data do aviso',
              causeValue: 'Valor da causa',
              judgingOrgan: 'Órgão julgador',
              communicationContent: 'Teor da comunicação',
              openedBy: 'Aberto por',
              openingDate: 'Data da abertura',
              deadline: 'Prazo',
              documentation: 'Documentação',
              waiting: 'não visto',
            },

            modal: {
              title: 'Avisos Pendentes',
              visualize: 'Visualizar',
              alert: {
                title: 'Visualizar aviso <b>{{id}}?</b>',
                text: 'Ao visualizar o aviso você <b>verá o teor da comunicação e será iniciado a contagem de prazo estabelecido pelo Tribunal de Justiça.</b>',
                confirmText: 'Visualizar',
                agreementText: 'Estou ciente de que a contagem do prazo se inicia ao clicar em visualizar.',
              },
            },
          },

          movement: {
            modal: {
              selectedProcedure: 'Processo de Nº <bold>{{number}}</bold> do fluxo <bold>{{flux}}</bold> selecionado',
              title: 'Histórico de Movimentações',
              countMovement: 'Movimentações ({{count}})',
            },

            table: {
              id: 'Código movimentação',
              dateMovement: 'Data movimentação',
              description: 'Movimentação',
              dateConsult: 'Data da consulta',
            },
          },

          error: {
            message: 'Erro ao tentar atualizar informações do PJE: o serviço está indisponível para consultar no momento.',
          },
        },
      },

      procedureList: {
        citationIntimation: {
          empty: 'Ainda não há citação/intimação em sua caixa. Quando houver, eles aparecerão aqui.',
          link: 'Cadastre uma nova citação/intimação',
          process_type: {
            citation: 'Citação',
            intimation: 'Intimação',
          },
          competence: {
            civil: 'Cível',
            labor: 'Trabalhista',
            federal: 'Federal',
            title: '<bold>Competência:</bold> {{value}}',
          },
          header: {
            processNumber: 'Processo a vincular',
            subject: 'Assunto',
            created_at: 'Criado em',
            observations: 'Observações',
            content: 'Teor',
          },
          type: {
            title: '<bold>Tipo:</bold> {{value}}',
          },
          responsible: '<bold>Responsável Atual:</bold>',
          time_inbox: '<bold>Tempo na caixa:</bold> {{value}}',
          recieved: '<bold>Recebimento:</bold> {{value}}',
          exercise: '<bold>Exercício:</bold> {{value}}',
          individualName: '<bold>Cadastrante:</bold> {{value}}',
          observation: {
            title: 'Observação',
            without: 'Sem Observação',
            with: 'Com Observação',
          },
          content: {
            title: 'Teor',
            without: 'Sem Teor',
            with: 'Com Teor',
          },
        },
        procedure: 'Processo',
        data: 'Dados',
        time: 'Agenda',
        notes: 'Anotações',
        instructions: 'Instruções',
        observations: 'Observações',
        created_at: 'Criado em',
        received_date: 'Recebimento',
        time_inbox: 'Tempo na caixa',
        inactive_time: 'Tempo inativo',
        procedure_deadline: 'Prazo',
        procedure_hearings_schedule: 'Audiências',
        last_product_generated: 'Último andamento',
        origin_procedure_name: 'Origem do processo',
        subject: 'Assunto',
        procedure_value: 'Valor',
        flux: 'Fluxo',
        current_responsible: 'Responsável atual',
        shared_with: 'Compartilhado com',
        deadlines: 'Prazos',
        previous_responsibles: 'Responsáveis anteriores',
        cdas_linked: 'CDAS vinculadas',
        process_category_name: 'Classificação',
        interested_parts: 'Parte(s) interessada(s)',
        interested_parts_title: '{{first_part}} e outras {{interested_parts_length}}',
        interested_parts_attributes: {
          title: 'Parte interessada',
          view_plus: 'Ver mais',
          anotations: 'Anotações:',
          parts: 'partes',
        },
        individual_name: 'Responsável atual',
        involved_people: 'Responsáveis anteriores',
        shared_people: 'Compartilhado com',
        empty: 'Ainda não há processos em sua caixa. Quando houver, eles aparecerão aqui.',
        link: 'Crie um novo processo',
        digitalFolder: 'Pasta digital',
        notRead: 'Não lido',
        requiriment_date: 'Data de Requerimento',
        no_requiriment_date: 'Sem data de requerimento',
        hearings_count: 'Audiências',
        no_hearings_count: 'Não há audiências',
        competence: 'Competência',
        no_competence: 'Não há competências',
      },

      actions: {
        title: 'Andamentos',
        results: {
          success: 'Sucesso ',
          error: 'Erro ',
        },

        deadline: {
          title: 'Definir Prazo',
          disabled: 'A ação definir prazo do processo encontra-se desabilitada para esta fase do(s) processo(s).',
        },

        unattach_procedure: {
          title: 'Desapensar processo',
          dependent: {
            title: 'Este é um processo dependente',
            subtitle: 'O processo será desvinculado do processo referência com os seguintes dados:',
            alert: {
              title: 'Confirmação de desapensamento',
              subtitle: 'Deseja realmente desapensar o processo dependente<br/>Nº {{dependent}} do processo referência Nº {{reference}} ?',
            },
          },
          reference: {
            title: 'Este é um processo referência',
            subtitle: 'Selecione os processos dependentes que deseja que seja desvinculado:',
            alert: {
              title: 'Confirmação de desapensamento',
              subtitle: 'Deseja realmente desapensar {{dependent}} processo(s) dependentes do processo<br/>referência Nº {{reference}} ?',
            },
          },
          button: 'Desapensar',
        },

        modalOutSideConfirmationAlert: {
          title: 'Deseja realmente sair?',
          text: 'As ações realizadas neste andamento serão perdidas',
        },
        citation_intimation: {
          modal: {
            title: 'Vincular citação/intimação',
            select: {
              title: 'Seleção de processo destino',
              tooltip: 'Processo cujo a citação/intimação será anexada.',
              subtitle: 'o processo escolhido abaixo será o processo destino da citação/intimação',
            },
            destination_procedure: {
              label: 'Nº do processo destino',
              placeholder: 'Pesquise por número de processo...',
            },
            no_procedures_found: {
              no_procedures: 'Número de processo <strong>{{procedure}}</strong> não possui cadastro no Àgilis.',
              create_procedure: 'Clique aqui para cadastrar o processo',
            },
            search: 'Pesquisar',
            button: 'Vincular',
          },
          tramit: {
            modal: {
              title: 'Tramitar citação/intimação',
            },
            alert: {
              title: 'Deseja realmente tramitar a citação/intimação N° {{citationIntimationSubject}} ?',
              subTitle: 'A citação/intimação selecionada será tramitada para a área: <bold>{{fieldName}}</bold>',
            },
            toast: {
              success: 'Citação/Intimação, tramitada com sucesso',
              error: 'Erro ao tramitar citação/intimação',
            },
          },
          attach: {
            title: 'Vincular citação/intimação?',
            text: 'Deseja vincular a citação/intimação ao <br>processo Nº {{number}}?<br/>',
            load: {
              title: 'Carregando...',
              text: 'Aguarde enquanto estamos vinculando sua citação/intimação.',
            },
            toast: 'Citação/Intimação vinculada com sucesso',
          },
          attach_file: {
            title: 'Anexar documento à citação/intimação?',
            text: 'Deseja Anexar a documentação selecionada a sua citação/intimação?',
            load: {
              title: 'Carregando...',
              text: 'Aguarde enquanto estamos anexando o documento à sua citação/intimação.',
            },
            toast: 'Documento anexado com sucesso!',
          },
          delete: {
            item: 'Excluir citação/intimação',
            title: 'Deseja realmente excluir?',
            text: 'A citação/intimação selecionado será excluída definitivamente.',
            toast: 'Citação/Intimação excluída com sucesso!',
            load: {
              title: 'Carregando...',
              text: 'Aguarde enquanto estamos excluindo sua citação/intimação.',
            },
          },
        },

        attach_procedure: {
          title: 'Apensar processo',
          disabled: 'A ação definir prazo do processo encontra-se desabilitada para esta fase do(s) processo(s).',
          alert: {
            title: 'Confirmação de apensamento',
            single: 'Deseja realmente apensar o processo Nº {{attach}} como dependente do processo referência Nº {{number}} ?',
            multiple: 'Deseja apensar o(s) processo(s) como <br/>dependente do processo referência Nº {{number}}?',
          },
          modal: {
            alertLoading: {
              results: {
                oneProcess: 'Error ao apensar processo',
              },
            },
            title: 'Apensar processo',
            button: 'Apensar',
            subtitle: 'Dependente -> Referência',
            description: 'Você irá vincular um processo dependente a um processo referência. Analise e cheque os dados em comum dos processos vinculados e selecione.',
            search: 'Pesquisar',
            input: {
              label: 'Nº do processo referência',
              placeholder: 'Pesquise por número de processo...',
            },
            dependent_procedure: {
              title: 'Dados do processo dependente',
              tooltip: 'Processo que será guiado pelo processo referência vinculado a ele.',
              table: {
                procedure: 'Processo',
                flux: 'Fluxo',
                subject: 'Assunto',
                current_responsbile: 'Responsável atual',
                interested_part: 'Parte interessada',
              },
            },
            reference_proceure: {
              title: 'Seleção de processo referência',
              tooltip: 'Processo que guiará os processos dependentes vinculados a ele.',
              subtitle: 'O processo escolhido abaixo será o processo referência do apenso.',
            },
          },
        },

        seenNotSeen: {
          markLikeUnread: 'Marcar como não lido',
          markLikeRead: 'Marcar como lido',
        },

        add_or_remove_marker: {
          title: 'Adicionar/remover marcadores',
          disabled: 'A ação adicionar/remover marcadores do processo encontra-se desabilitada para esta fase do(s) processo(s).',
        },

        attachCitationIntimation: {
          title: 'Vincular citação/intimação',
          modal: {
            process_number: 'Número do processo',
            competence: 'Competência',
            exercise: 'Exercício',
            content: 'Teor',
            subject: 'Assunto',
            button: 'Vincular',
            search: 'Buscar',
          },
          select: {
            label: 'Nº de processo a ser vinculado',
            placeholder: 'Pesquise por número de processo...',
            title: 'Seleção de citação/intimação destino',
            tooltip: 'Citação/intimação que será vinculada ao processo.',
            subtitle: 'A citação/intimação escolhida abaixo será a citação/intimação destino do processo',
          },
          attach: {
            title: 'Vincular citação/intimação?',
            text: 'Deseja vincular a citação/intimação ao <br>processo Nº {{number}}?<br/>',
            load: {
              title: 'Carregando...',
              text: 'Aguarde enquanto estamos vinculando sua citação/intimação.',
            },
            toast: 'citação/intimação vinculada com sucesso',
          },
        },

        notes: {
          title: 'Anotações',
          disabled: 'A ação anotações do processo encontra-se desabilitada para esta fase do(s) processo(s).',
          modal: {
            title: 'Anotações do processo',
            timeline: 'Histórico de anotações',
            bulk: 'Anotado em massa em {{number}} processos',
            snack: {
              deleted: 'A anotação foi excluída.',
              undo: 'Desfazer',
            },
            observation: {
              title: 'Nova anotação',
              placeholder: 'Digite uma anotação',
              create: 'Adicionar anotação',
            },
            options: {
              edit: 'Editar neste processo',
              edit_in_bulk: 'Editar em massa',
              delete_in_bulk: 'Excluir em massa',
              delete: 'Excluir neste processo',
            },
            buttons: {
              save: 'Salvar',
              cancel: 'Cancelar',
            },
            exibition: {
              public: 'Exibição Pública',
              private: 'Exibição Privada',
              tooltip: 'Ao habilitar, a anotação ficará de modo público, ou seja, qualquer colaborador com acesso ao processo poderá visualizar',
            },
          },
        },

        multipleActions: {
          title: 'Múltiplos andamentos',
          available: 'Andamentos disponiveis',
          availableMessage: 'Arraste pelo menos dois andamentos que deseja fazer em sequência para a sessão "Andamentos selecionados"',
          selected: 'Andamentos selecionados',
          selectedMessage: 'Visualize os andamentos selecionados e mude sua sequência se necessário arrastando os itens',
          selectedText: '<bold>Arraste aqui</bold> os andamentos que deseja listados no "Andamentos disponíveis"',
          submit: 'Iniciar múltiplos andamentos',
          default: 'Múltiplos andamentos padrão',
          modal: {
            title: 'Deseja realmente iniciar os múltiplos andamentos no processo Nº {{number}}?',
            titleManyProcedures: 'Deseja realmente iniciar os múltiplos andamentos em {{number}} processos?',
            text: 'Os andamentos foram selecionados nessa sequência: {{actions}}',
            error: 'Não foi possível seguir com os múltiplos andamentos',
            errorMsg: 'O andamento <bold>{{action}}</bold> deve ser o último andamento a ser realizado.',
            actionsEmpty: 'Você ainda não criou nenhuma sequência. Crie sequência de até 5 andamentos',
            actions: {
              empty: 'Você ainda não criou nenhuma sequência. Crie sequência de até 5 andamentos',
              create: 'Crie 5 sequências de até 5 andamentos',
            },
          },
          observation: {
            title: 'Agilize seu trabalho!',
            subtitle: 'Escolha uma sequência de andamentos para ser realizado em cadeia no(s) processo(s) selecionado(s)',
          },
          sections: {
            default: {
              title: 'Múltiplos andamentos padrão',
            },
            customize: {
              new: 'Nova sequência',
              title: 'Múltiplos andamentos pessoais',
              message1: 'Selecione os andamentos que deseja fazer em sequência:',
              message2: 'Visualize os andamentos selecionados e mude sua sequência arrastando os itens',
              cancel: 'Cancelar',
              save: 'Salvar sequência',
            },
          },
        },

        changeFlux: {
          title: 'Alterar fluxo',
          disabled: 'A ação alterar fluxo encontra-se desabilitada para este(s) processo(s).',
          modal: {
            selectedProcedure: 'Processo de Nº <bold>{{number}}</bold> do fluxo <bold>{{flux}}</bold> selecionado',
            selectedManyProcedures: '<bold>{{number}}</bold> processo(s) do fluxo <bold>{{flux}}</bold> selecionado(s)',
            unselect: 'Desselecionar todos',
            form: {
              submit: 'Alterar',
              title: 'Informações de alteração de fluxo',
              newFlux: 'Novo Fluxo',
              newFluxPlaceholder: 'Selecione um novo fluxo',
              observation: 'Observação de alteração',
              observationPlaceholder: 'Digite uma observação de alteração de fluxo',
            },
            alert: {
              singleTitle: 'Deseja realmente alterar o fluxo do processo?',
              pluralTitle: 'Deseja realmente alterar o fluxo dos {{procedureLength}} processos?',
              text: '<br/>O fluxo atual será substituído pelo fluxo <b>{{fluxName}}</b> no(s) processo(s)<br/>',
            },
            alertLoading: {
              title: 'Alterar fluxo',
              text: 'Fluxo de processos alterado para {{fluxName}}',
              results: {
                oneProcess: ' ao alterar fluxo de 1 processo.',
                manyProcedures: ' ao alterar fluxo de {{number}} processos.',
                progress: ' fluxo de processo(s) alterado(s)',
              },
            },
          },
        },

        tramit: {
          title: 'Tramitar processo(s)',
          disabled: 'A ação tramitar processo encontra-se desabilitada para esta fase do(s) processo(s).',
          modal: {
            selectedProcedure: 'Processo de Nº <bold>{{number}}</bold> do fluxo <bold>{{flux}}</bold> selecionado',
            selectedManyProcedures: '<bold>{{number}}</bold> processo(s) do fluxo <bold>{{flux}}</bold> selecionado(s)',
            unselect: 'Desselecionar todos',
            suggestions: {
              title: 'Tramitações mais frequentes',
              empty: 'Nenhuma encontrada',
            },
            form: {
              submit: 'Tramitar',
              title: 'Informações do destinatário',
              institution: 'Instituição',
              institutionPlaceholder: 'Selecione uma instituição',
              field: 'Área',
              fieldPlaceholder: 'Selecione uma área',
              individual: 'Colaborador',
              individualPlaceholder: 'Selecione uma colaborador',
              observation: 'Observação de trâmite',
              observationPlaceholder: 'Digite uma observação',
            },
            alertConfirmation: {
              title: 'Deseja realmente tramitar o processo Nº {{number}}?',
              text: 'O processo Nº {{number}} para <bold>{{individualName}}</bold>',
              titleManyProcedures: 'Deseja realmente tramitar os {{number}} processos?',
              textManyProcedures: '<bold>{{number}}</bold> processos para <bold>{{individualName}}</bold>',
            },
            alertLoading: {
              title: 'Tramitar processo(s)',
              text: 'Trâmite para caixa de entrada de <bold>{{individual}}</bold> e envio para sua caixa de saída.',
              results: {
                oneProcess: ' ao tramitar 1 processo.',
                manyProcedures: ' ao tramitar {{number}} processos.',
                progress: ' processo(s) tramitado(s)',
              },
            },
          },
        },

        distributionManual: {
          modal: {
            alertLoading: {
              results: {
                oneProcess: ' ao distribuir 1 processo.',
                manyProcedures: ' ao distribuir {{number}} processos.',
                progress: ' processo(s) distribuido(s)',
              },
            },
          },
        },

        distributionAutomatic: {
          modal: {
            alertLoading: {
              results: {
                oneProcess: ' ao distribuir 1 processo.',
                manyProcedures: ' ao distribuir {{number}} processos.',
                progress: ' processo(s) distribuido(s)',
              },
            },
          },
        },

        shareProcedure: {
          title: 'Compartilhar processo',
          sharedWith: 'Compartilhado com',
          delete: 'Descompartilhar',
          tooltip: 'Obs. de compartilhamento',
          createdBy: 'Compartilhado por {{individual_name}} em {{tramit_date}}',
          empty: 'Não há observação',
          markAsFinished: 'Marcar como feito',
          done: 'Feito',
          error: 'Falha ao marcar observação como feita',
          form: {
            header: 'Informações do destinatário',
            text: 'Ao compartilhar o(s) processo(s) com outros colaboradores, você estará permitindo que façam qualquer andamento disponível no processo com exceção de Tramitar, Distribuir, Compartilhar e Arquivar.',
            institution: 'Instituição*',
            field: 'Área*',
            individuals: 'Colaboradores*',
            observation: 'Observação de compartilhamento',
            submit: 'Compartilhar',
          },
          alertConfirmation: {
            title: 'Confirmação de compartilhamento',
            text: 'Deseja realmente compartilhar o processo {{number}} para {{individuals}} colaboradores?',
            textManyProcedures: 'Deseja realmente compartilhar {{number}} processos para {{individuals}} colaboradores?',
          },
          deleteModal: {
            title: 'Deseja realmente descompartilhar esse processo?',
            text: 'O responsável atual pelo processo, {{individualName}}, será notificado sobre a ação',
          },
          modal: {
            alertLoading: {
              title: 'Compartilhar processo',
              text: 'Após o compartilhamento, os colaboradores selecionados serão notificados e encontraram o(s) processo(s) em sua Caixa compartilhada.',
              results: {
                oneProcess: ' ao compartilhar 1 processo.',
                manyProcedures: ' ao compartilhar {{number}} processos.',
                progress: ' processo(s) compartilhado(s)',
              },
            },
          },
          remove: {
            title: 'Confirmação de remoção de compartilhamento',
            text: 'Deseja realmente remover {{name}} dos compartilhamento do processo Nº {{number}} ?',
            success: 'Colaborador "{{name}}" foi removido do compartilhamento.',
            error: 'Erro ao remover colaborador do compartilhamento.',
          },
        },

        distributeProcesses: {
          title: 'Distribuir Processos',
          disabled: 'A ação tramitar processo encontra-se desabilitada para esta fase do(s) processo(s).',
          modal: {
            form: {
              title: 'Informações de distribuição',
              distributionType: 'Tipo de distribuição',
              area: 'Área',
              arePlaceholder: 'Selecione uma área',
              unitField: 'Unidade de exercício',
              unitFieldPalceHolder: 'Selecione uma unidade de Exercício',
              functions: 'Função',
              functionsPlaceHolder: 'Selecione uma ou mais funções',
              observation: 'Observações da distribuição',
              observationPlaceHolder: 'Digite aqui uma observação...',
              submit: 'Distribuir',
            },
            distributePerCollaborator: {
              manual: 'Distribuição por processo',
              automatic: 'Distribuição por colaborador',
              tip: 'Preencha os campos de informação e a lista de contribuintes dentro dos seus critérios carregará aqui.',
              collaboratorsTable: {
                process: 'Processo',
                selection: 'Seleção',
                collaborator: 'Colaborador',
                currentCollection: 'Acervo Atual',
                receivedOnTheDay: 'Recebidos no dia',
                dayOuts: 'Saídas do dia',
                distributeQuantity: 'Quantidade a distribuir',
                noCollaboratorOption: 'Não há colaboradores',
              },
              progressBar: {
                title: 'Progresso total',
                progressInfo: '{{current}} de {{total}} processos prontos para distribuição',
              },
              distributeQuantityError: {
                outQuantity: 'Número maior que o disponível para distribuir',
              },
            },
            alertConfirmation: {
              title: 'Confirme sua distribuição',
              text: 'Deseja realmente distribuir {{procedureNumber}} processo(s) para {{collaboratorNumber}} colaborador(es) ?',
            },
            alertLoading: {
              title: 'Distribuir processos',
              text: 'Os processos distribuidos irão para caixa de entrada dos colaboradores selecionados e para a sua caixa de saída',
              results: {
                oneProcess: ' ao distribuir 1 processo.',
                manyProcedures: ' ao distribuir {{number}} processos.',
                progress: ' processo(s) distribuido(s)',
              },
            },
          },
        },

        forceTramit: {
          title: 'Avocar processo(s)',
          disabled: 'A ação avocar processo encontra-se desabilitada para esta fase do(s) processo(s).',
          force_alert: {
            title_error: {
              procedures: 'Você não tem permissão para avocar os {{total_procedures}} processos',
              procedure: 'Você não tem permissão para avocar o processo Nº {{procedure_number}}',
            },
            title_warning: 'Você não tem permissão para avocar {{total_procedures_error}} dos {{total_procedures}} processos. Deseja seguir com os {{procedures_force_tramit}} processos?',
            text: 'Você só pode avocar processos que estejam na sua lotação principal.',
            buttonConfirm: 'Confirmar',
            buttonCancel: 'Cancelar',
          },
          modal: {
            textLabel: 'Observações da avocação',
            textPlaceholder: 'Digite o motivo da avocação',
            infos: 'Informações',
            buttonText: 'Avocar',
            modal_alert: {
              procedure: {
                title: 'Deseja realmente avocar o processo Nº {{procedure_number}}?',
                text: 'O processo <a>Nº {{procedure_number}}</a> irá para a Caixa de Entrada.',
              },
              procedures: {
                title: 'Deseja realmente avocar os {{total_procedures}} processos?',
                text: '{{total_procedures}} processos irão para sua Caixa de Entrada.',
              },
              buttonConfirm: 'Sim',
              buttonCancel: 'Não',
            },
            alertLoading: {
              title: 'Avocar processo(s)',
              text: 'O(s) processos(s) irão para sua Caixa de Entrada.',
              results: {
                oneProcess: ' ao avocar 1 processo.',
                manyProcedures: ' ao avocar {{number}} processos.',
                progress: ' processo(s) avocado(s)',
              },
            },
          },
        },

        prepareDocument: {
          title: 'Elaborar/editar documento',
          disabled: 'A ação elaborar/editar documento encontra-se desabilitada para esta fase do(s) processo(s).',
          modal: {
            title: 'Elaborar documento',
            newDocumentType: {
              title: 'Novo tipo de documento',
              button: 'Criar novo tipo de documento',
            },
            toast: {
              info: 'Para novos campos ao digitar @, por favor, entre em contato com o <a href="https://wa.me/558589260327" target="_blank">ServiceDesk</a>.',
              success: 'O modelo de documento foi criado com sucesso.',
              error: 'O modelo de documento não foi criado com sucesso.',
            },
            actions: {
              error: 'Erro ao excluir modelo de documento',
              title: 'Deseja realmente excluir modelo de documento?',
              subtitle: 'O modelo de documento será excluído.',
            },
            tabs: {
              edit: '1. Formatação',
              view: '2. Pré-visualização',
            },
            form: {
              selectGroup: {
                empty: 'Sem modelo',
                new: 'Novo modelo',
                newTemplate: '+ Criar modelo',
                template: 'Modelo',
                byMe: 'Criado por mim',
              },
              list: {
                fileName: 'Arquivo',
                type: 'Tipo do documento*',
                typePlaceholder: 'Selecione o tipo',
                sign: 'Assinar',
                proceeding: {
                  label: 'Tipo de procedimento',
                  placeholder: 'Selecione o tipo',
                  tooltip: 'Com base na lei 8664 de 10/12/2002',
                },
                signed: 'Assinado?',
                delete: 'Excluir',
                deleteTooltip: 'Excluir arquivo',
              },
              tooltip: {
                modelName: 'Nomeie o modelo a ser criado. Lembre-se de que ele ficará visível para outros usuários.',
                documentName: 'Nomeie o documento a ser criado.',
                modelInfo: 'Escolha um modelo de documento para inserir uma estrutura predefinida ao corpo do texto.',
                variables: 'Escreva o texto do documento e formate com a caixa de ferramentas. Para inserir campos pré-definidos, digite @.',
              },
              name: 'Nome do modelo',
              docName: 'Nome do documento',
              label: 'Corpo do Texto',
              placeholder: 'Insira um nome para o modelo',
              docPlaceholder: 'Insira um nome para o documento',
              agreeShare: 'Concordo que ao salvar o modelo de documento, será utilizado o padrão visual da Prefeitura Municipal de Fortaleza.',
              agreeVisible: 'Concordo que este modelo ficará disponível para ser utilizado por outros colaboradores com a mesma função do usuário que criou.',
              save: 'Salvar modelo',
              cancel: 'Cancelar',
              characterError: 'O nome do documento não deve conter \' # . % * : <> ? /\\ | ` ^ ',
              sign: {
                signed: 'Ass. externo ',
                notSigned: 'Não identificado',
              },
              switch: 'Replicar o tipo do documento em todos os arquivos',
            },
            modal_alert: {
              procedure: {
                title: 'Deseja realmente elaborar o documento?',
                subtitle: 'O documento será elaborado e anexado ao processo de <a>Nº {{procedure_number}}</a>',
              },
              procedures: {
                title: 'Deseja realmente elaborar o documento para {{number}} processos?',
                subtitle: 'O documento será elaborado e anexado aos processos.',
              },
            },
            alertLoading: {
              title: 'Elaborar documento',
              text: 'Documentos elaborados poderão ser vistos em detalhes do(s) processo(s).',
              results: {
                oneProcess: ' ao elaborar o documento.',
                manyProcedures: ' ao elaborar o documento em {{number}} processos.',
                progress: {
                  oneProcess: 'Processo de Nº {{number}} {{not}} recebeu o documento elaborado.',
                  manyProcess: '{{number}} de {{total}} processos {{not}} receberam o documento elaborado.',
                },
              },
            },
          },
        },

        attachFiles: {
          title: 'Anexar documentos',
          disabled: 'A ação anexar documento encontra-se desabilitada para esta fase do(s) processo(s).',
          modal: {
            label: 'Anexos',
            submit: 'Anexar documentos',
            alertLoading: {
              text: 'Documentos anexados poderão ser vistos em detalhes do(s) processo(s).',
              results: {
                progress: ' processo(s) com documento(s) anexado(s)',
                oneProcess: ' ao anexar documento(s) em 1 processo.',
                manyProcedures: ' ao anexar documento(s) em {{number}} processos.',
              },
            },
          },
        },

        downloadDocument: {
          title: 'Baixar documentos',
          disabled: 'A ação baixar documento encontra-se desabilitada para esta fase do(s) processo(s).',
          modal: {
            submit: 'Baixar documentos',
            alert: {
              title: 'Deseja realmente baixar {{qnt_doc}} documento{{plural}} do processo Nº {{procedure_number}}?',
              pluralTitle: 'Deseja realmente baixar {{qnt_doc}} documento{{plural}} de {{procedure_qnt}} processos',
              button: 'Baixar',
              text: 'Para baixar o(s) arquivo(s) já anexados ao processo, será necessário selecionar quais deverão ser baixados. Após isso, clique em "Baixar".',
            },
          },
        },

        removeDocument: {
          title: 'Excluir documentos',
          disabled: 'A ação excluir documento encontra-se desabilitada para esta fase do(s) processo(s).',
          modal: {
            label: 'Documentos',
            submit: 'Excluir documentos',
            warning: 'Atenção!',
            text: 'Para excluir o(s) arquivo(s) já anexados ao processo, será necessário selecionar quais deverão ser excluídos. Após isso, clique em "Excluir". Ao aplicar a exclusão do(s) arquivo(s), <strong>o sistema irá registrar essa ação na linha do tempo do processo</strong> e não mostrará mais o arquivo na aba de "Documentos Anexados", na tela de detalhes do processo.',
            switch: 'Exibir anexos não assinados',
            documentList: {
              processNumber: 'Número do processo',
              size: 'Tamanho',
              fileName: 'Arquivo',
              date: 'Data do anexo',
              owner: 'Anexado por',
              type: 'Tipo de documento',
              signed: 'Assinado?',
              cosigned: 'Co-assinado?',
              cosignedText: 'Co-assinado ',
              duplicate: 'Documento assinado duplicado.',
            },
            alert: {
              title: 'Deseja realmente excluir {{qnt_doc}} documento{{plural}} do processo Nº {{procedure_number}}?',
              pluralTitle: 'Deseja realmente excluir {{qnt_doc}} documento{{plural}} de {{procedure_qnt}} processos',
              text: 'Sua exclusão será registrada na linha do tempo.',
              button: 'Excluir',
            },
            alertLoading: {
              title: 'Excluir documentos',
              text: 'Será registrado na linha do tempo e não mostrará mais os documentos nos detalhes do(s) processo(s).',
              results: {
                success: 'ao excluir {{qnt_doc}} documento{{plural_doc}} em {{qnt_procedure}} processo{{plural_procedure}}.',
                error: 'ao excluir {{qnt_doc}} documento{{plural_doc}} em {{qnt_procedure}} processo{{plural_procedure}}.',
                progress: ' documento(s) excluído(s)',
              },
            },
          },
          alertError: {
            title: 'Não existem arquivos em comum nos processos selecionados.',
            text: 'Apenas arquivos com o mesmo <strong>nome, data e autor da anexagem</strong> entre os processos podem ser excluídos em massa. Se deseja excluir arquivos específicos, selecione os processos individualmente.',
          },
          alertWarning: {
            title: 'Você selecionou mais de um processo, apenas arquivos em comum poderão ser excluídos.',
            text: 'Apenas arquivos com o mesmo <strong>nome, data e autor da anexagem</strong> entre os processos serão exibido para a exclusão.',
            continue: 'Continuar',
            cancel: 'Cancelar',
          },
        },

        unarchive: {
          button: 'Desarquivar',
          title: 'Desarquivar processo(s)',
          subtitle: 'Informações do desarquivamento',
          label: 'Observações do desarquivamento*',
          placeholder: 'Digite uma observação',
          modal: {
            alertModal: {
              single: {
                title: 'Deseja realmente desarquivar<br>o processo Nº {{ number }}?',
                text: 'O processo <span> Nº {{ number }}</span> irá para sua Caixa de Entrada.',
              },
              multiple: {
                title: 'Deseja realmente desarquivar<br>os {{ number }} processos?',
                text: '<bold>{{ number }}</bold> processos irão para sua caixa de Entrada.',
              },
            },
            alertLoading: {
              title: 'Desarquivar processo(s)',
              text: 'Os processos irão para sua caixa de entrada',
              results: {
                errorResult: 'Não foi possível desarquivar os processos.',
                oneProcess: ' ao desarquivar 1 processo.',
                manyProcedures: ' ao desarquivar {{number}} processos.',
                progress: ' processos(s) desarquivado(s)',
              },
            },
          },
          individual: {
            alertError: {
              title: 'Você não tem permissão para<br>desarquivar o processo Nº {{ number }}.',
              text: 'Você só pode desarquivar processos que estejam na sua lotação principal.',
            },
          },
          bulk: {
            alertError: {
              title: 'Você não tem permissão para<br>desarquivar os {{ amount }} processos.',
              text: 'Você só pode desarquivar processos que estejam na sua lotação principal.',
            },
          },
        },

        suspensionCancellationRequest: {
          title: 'Solicitar Suspensão/Cancelamento de Créditos',
          tramitTitle: 'Tramitar e Solicitar Suspensão/Cancelamento de Créditos',
          disabled: 'A ação de suspensão/cancelamento de créditos encontra-se desabilitada para esta fase do(s) processo(s).',
          disabledByLength: 'A ação de suspensão/cancelamento de créditos não está disponível para múltiplos processos.',
          modal: {
            title: 'Você deseja realmente suspender/cancelar as {{number}} CDAs?',
            confirm: 'Continuar',
            cancel: 'Cancelar',
            alertLoading: {
              title: 'Suspensão/Cancelamento de Créditos',
              text: 'SEFIN',
              results: {
                errorResult: 'Não foi possível suspender/cancelar as inscrições',
                oneProcess: ' ao suspender/cancelar as inscrições.',
                progress: 'processos com inscrições suspensas/canceladas',
              },
            },
          },
        },

        suspensionCancellationTramit: {
          title: 'Tramitar e Solicitar Suspensão/Cancelamento de Créditos',
          onlyTramitTitle: 'O valor total das CDAs suspera o limite de R$ 50000,00. Tramite o processo para o Chefe da Célula da Dívida Ativa - PGM',
          disabled: 'A ação de tramitação e suspensão/cancelamento de créditos encontra-se desabilitada para esta fase do(s) processo(s).',
          disabledByLength: 'A ação de tramitação e suspensão/cancelamento de créditos não está disponível para múltiplos processos.',
          modal: {
            title: 'Você deseja realmente tramitar e suspender/cancelar as {{number}} CDAs?',
            confirm: 'Continuar',
            cancel: 'Cancelar',
            alertLoading: {
              title: 'Tramitação e Suspensão/Cancelamento de Créditos',
              text: 'SEFIN',
              results: {
                errorResult: 'Não foi possível tramitar e suspender/cancelar as inscrições',
                oneProcess: ' ao tramitar e suspender/cancelar as inscrições.',
                progress: 'processo(s) tramitado(s)',
              },
            },
          },
        },

        archive: {
          title_procedure: 'Arquivar processo',
          title_procedures: 'Arquivar processos',
          disabled: 'A ação arquivar encontra-se desabilitada para esta fase do(s) processo(s).',
          archive_fields: {
            info: 'Informações do arquivamento',
            reason: 'Motivo',
            select_reason: 'Selecione um motivo',
            observation: 'Observações do arquivamento',
            placeholder_observation: 'Digite uma observação',
            button_text: 'Arquivar',
          },
          archive_alert: {
            title_procedures: 'Deseja realmente arquivar os {{ proceduresSelectedLength }} processos?',
            title_procedure: 'Deseja realmente arquivar o processo  Nº {{ procedureNumber }}?',
            label_procedures: 'Os {{ proceduresSelectedLength }} processos irão para sua caixa de arquivados.',
            label_procedure: 'O processo <a>Nº {{ procedureNumber }}</a> irá para sua caixa de arquivados.',
          },
          modal: {
            alertLoading: {
              title: 'Aquivar processo(s)',
              text: 'Os processos irão para sua caixa de arquivados',
              results: {
                errorResult: 'Não foi possível arquivar os processos',
                oneProcess: ' ao arquivar 1 processo.',
                manyProcedures: ' ao arquivar {{number}} processos.',
                progress: ' processos(s) arquivado(s)',
              },
            },
          },
        },

        delete: {
          title_procedure: 'Remover processo',
          disabled: 'A ação remover encontra-se desabilitada para multiplos processos.',
          modal: {
            alert: {
              title: 'Deseja realmente remover o processo?',
              text: 'A remoção é permantente e a operação não poderá ser desfeita. Deseja continuar?',
              confirm: 'Sim',
              cancel: 'Não',
            },
            results: {
              error: 'Não foi possível remover o processo',
              success: 'Processo removido com sucesso',
            },
          },
        },
        attachProcedure: {
          title: 'Apensar processos',
          text: 'Após o apensamento, o vínculo ficará registrado na linha do tempo e no extrato dos processos envolvidos.',
          modal: {
            alertLoading: {
              title: 'Apensar processos',
              text: 'Após o apensamento, o vínculo ficará registrado na linha do tempo e no extrato dos processos envolvidos.',
              results: {
                oneProcess: ' ao apensar 1 processo.',
                manyProcedures: ' ao apensar {{number}} processos.',
                progress: ' processo(s) apensados.',
              },
            },
          },
        },
        unattachProcedure: {
          title: 'Desapensar processos',
          text: 'Após o desapensamento, o vínculo ficará registrado na linha do tempo e no extrato dos processos envolvidos.',
          modal: {
            alertLoading: {
              title: 'Desapensar processos',
              text: 'Após o desapensamento, o vínculo ficará registrado na linha do tempo e no extrato dos processos envolvidos.',
              results: {
                oneProcess: ' ao desapensar 1 processo.',
                manyProcedures: ' ao desapensar {{number}} processos.',
                progress: ' processo(s) desapensados.',
              },
            },
          },
        },
        attachExtract: {
          title: 'Anexar extrato de débitos',
          disabled: 'A ação anexar extrato de débitos encontra-se desabilitada para esta fase do(s) processo(s).',
          modal: {
            alert: {
              title: 'Deseja realmente gerar o extrato da dívida para o(s) processo(s) abaixo?',
              confirm: 'Sim',
              cancel: 'Não',
            },
            alertLoading: {
              title: 'Anexar documento(s)',
              text: 'Os documentos anexados poderão ser vistos em detalhes do(s) processo(s).',
              results: {
                errorResult: 'Não foi possível anexar os processos',
                oneProcess: ' ao anexar 1 processo.',
                manyProcedures: ' ao anexar em {{number}} processos.',
                progress: ' documento(s) anexado(s)',
              },
            },
          },
        },

        unArchive: {
          title: 'Desarquivar processos',
          disabled: 'A ação desarquivar encontra-se desabilitada para esta fase do(s) processo(s).',
        },

        sign_document: {
          title: 'Assinar documento',
          disabled: 'A ação assinar documento encontra-se desabilitada para esta fase do(s) processo(s).',
          modal: {
            toast: 'Para assinar é necessário ter a extensão instalada. Caso não possua a extensão, fale com o <a href="https://wa.me/558589260327" target="_blank">ServiceDesk</a>.',
            toastAttach: 'Para assinar é necessário estar em um desktop no navegador Firefox com a extensão instalada. Caso não possua a extensão, fale com o <a href="https://wa.me/558589260327" target="_blank">ServiceDesk</a>.',
            submit: 'Assinar documentos',
            titleList: 'Verifique os documentos que serão assinados no processo:',
            titleListManyProcedures: 'Verifique os documentos que serão assinados em cada processo:',
            documentList: {
              fileName: 'Arquivo',
              date: 'Data do anexo',
              owner: 'Anexado por',
              type: 'Tipo de documento',
            },
          },
          alertRequeriments: {
            title: 'Não é possível assinar documentos',
            text: 'Para assinar é necessário estar em um dispositivo <strong>desktop</strong>, com um navegador <strong>Firefox</strong> e a <strong>extensão instalada</strong>. Caso não possua a extensão, fale com o <a href="https://wa.me/558589260327" target="_blank">ServiceDesk</a>.',
          },
          alertConfirmation: {
            title: 'Deseja realmente assinar {{count}} documento(s) do processo Nº {{number}}?',
            text: '{{count}} documento(s) serão assinados e anexados no processo Nº {{number}} e aparecerá nos seus detalhes.',
            titleManyProcedures: 'Deseja realmente assinar {{count}} documento(s) de {{number}} processo(s)?',
            textManyProcedures: '{{count}} documento(s) serão assinados e anexados em seus respectivos processos e aparecerá nos seus detalhes.',
          },
        },

        signer: {
          signer: 'Assinar documentos',
          cosigner: 'Co-assinar documentos',
          signerA1: 'Assinar documentos via A1',
          modal: {
            alertLoading: {
              title: 'Assinar documentos',
              text: 'Assinatura de documentos',
              results: {
                oneProcess: ' ao assinar 1 documento.',
                manyProcedures: ' ao assinar {{number}} documentos.',
                progress: ' documento(s) assinado(s)',
              },
            },
            login: {
              password: {
                title: 'Sua senha do token',
                placeholder: 'Digite a senha do token',
              },
              observation: 'Assinador via Token USB',
              return: 'Voltar',
              send: 'Confirmar senha',
            },
            notice: {
              title: 'Há {{notAbleSign}} de {{allProceduresCount}} processos sem documentos aptos para assinatura. Deseja seguir com os {{ableSignCount}} processos?',
              text: 'Você só pode assinar processos que tenham documentos que foram anexados previamente e não possuem assinaturas.',
            },
          },
        },

        cosigner: {
          title: 'Co-assinar documentos',
          modal: {
            title: 'Apenas documentos que já foram assinados estão na listagem e podem ser co-assinados.',
            subtitle: 'Verifique-os documento e selecione os documentos que deseja co-assinar.',
            toast: 'Para assinar é necessário ter a extensão instalada. Caso não possua a extensão, fale com o <a href="https://wa.me/558589260327" target="_blank">ServiceDesk</a>.',
            notice: {
              title: 'Há {{notAbleSign}} de {{allProceduresCount}} processos sem documentos aptos para andamento. Deseja seguir com os {{ableSignCount}} processos?',
              text: 'Você só pode co-assinar documentos que foram anexados e assinados previamente.',
            },

            alertLoading: {
              title: 'Co-assinar documentos',
              text: 'Co-assinatura de documentos',
              results: {
                oneProcess: ' ao co-assinar 1 documento.',
                manyProcedures: ' ao co-assinar {{number}} documentos.',
                progress: ' documento(s) co-assinado(s)',
              },
            },

            alertConfirmation: {
              title: 'Deseja Realmente co-assinar {{docCount}} documento(s) de {{count}} processo(s)?',
              text: '{{count}} documento(s) serão assinados e anexados em seu respectivos processos e aparecerá nos seus detalhes',
            },

            submit: 'Co-assinar documentos',
          },
        },

        signerA1: {
          title: 'Assine com A1',
          modal: {
            alertLoading: {
              title: 'Assinar documentos',
              text: 'Assinatura de documentos',
              results: {
                oneProcess: ' ao assinar 1 documento.',
                manyProcedures: ' ao assinar {{number}} documentos.',
                progress: ' documento(s) assinado(s)',
              },
            },
            notice: {
              title: 'Há {{notAbleSign}} de {{allProceduresCount}} processos sem documentos aptos para assinatura. Deseja seguir com os {{ableSignCount}} processos?',
              text: 'Você só pode assinar processos que tenham documentos que foram anexados previamente e não possuem assinaturas.',
            },

            submit: 'Assinar Documentos',

            excessAttempts: 'Máximo de tentativas atingido.',
          },

          auth: {
            title: 'Acesso ao certificado A1',
            observation: 'Para continuar insira sua senha do certificado A1',
            password: {
              title: 'Sua senha',
              placeholder: 'Digite a senha do certificado',
            },
            send: 'Confirmar',
            return: 'Cancelar',
          },
        },

        forwardIPM: {
          modal: {
            alertLoading: {
              title: 'Encaminhar processo(s) para IPM',
              text: 'O(s) processo(s) serão encaminhados para IPM.',
              results: {
                oneProcess: ' ao encaminhar 1 processo.',
                manyProcedures: ' ao encaminhar {{number}} processos.',
                progress: ' processo(s) encaminhado(s)',
              },
            },

            alert: {
              procedures: {
                title: 'Deseja realmente encaminhar os {{ number }} processos?',
                subtitle: 'Os {{ number }} processos irão para sua caixa de arquivados.',
              },

              procedure: {
                title: 'Deseja realmente encaminhar o processo  Nº {{ procedureNumber }}?',
                subtitle: 'O processo <a>Nº {{ procedureNumber }}</a> irá para sua caixa de arquivados.',
              },
            },
          },
        },

        expandedMarkers: {
          modal: {
            title: 'Marcadores personalizados',
            inputText: {
              label: 'Busque por marcadores',
              placeholder: 'Digite uma marcador...',
            },
            button: 'Aplicar marcadores',
          },
        },

        expandedInterestedParts: {
          modal: {
            title: 'Partes interessadas',
            active: 'Parte Ativa',
            passive: 'Parte Passiva',
            undefinedPart: 'Parte Não definida',
          },
        },

        initialPetition: {
          modal: {
            text: 'Petição inicial PJE',
            basicData: {
              text: 'Dados básicos',
              proceduralClass: 'Classe processual',
              shareValue: 'Valor da ação',
              locationCode: 'Código localidade',
              representativeName: 'Nome representante',
              levelOfSecrecy: 'Nível sigilo',
              typeOfRepresentative: 'Tipo de representante',
              mainSubject: 'Assunto principal',
              symbol: 'R$ {{ value }}',
            },
            procedureParts: {
              title: 'Partes do processo ({{ procedureParts }})',
              pole: 'Pólo',
              personType: 'Tipo de pessoa',
              name: 'Nome',
              documentType: 'Tipo de documento',
              documentCode: 'Código do documento',
              documentIssuer: 'Emissor do documento',
              content: {
                active: 'AT - Ativo',
                passive: 'PS - Passivo',
                legal: 'Jurídica',
                physical: 'Física',
                cnpj: 'CNPJ - Cadastro Nacional da Pessoa Jurídica',
                cpf: 'CPF - Cadastro de Pessoa Física',
                irs: 'Receita Federal',
                ssp: 'ssp',
              },
            },
            documentSend: {
              text: 'Documentos para envio',
              documentSelect: 'Os documentos selecionados serão peticionados.',
              info: 'Pelo menos um documento precisa estar selecionado.',
              types: {
                others: 'outros',
                petition: 'petição',
              },
            },
            alertLoading: {
              title: 'Petição inicial PJE',
              text: 'O tribunal receberá a petição e retornará um número de protocolo. Após isso, o(s) processo(s) irá(ão) para a sua caixa de arquivados. <strong>Atenção: Não feche essa janela até o envio ser finalizado.</strong>',
              results: {
                progress: 'petição enviada',
                oneProcess: 'ao enviar a petição via PJE.',
                manyProcedures: ' ao peticionar {{number}} processos.',
              },
            },
            button: 'Peticionar',
            login: {
              title: 'Acesso ao PJE',
              observation: 'Para continuar insira sua senha PJE',
              user: 'Usuário',
              password: {
                title: 'Sua senha Pje',
                placeholder: 'Digite a senha do usuário',
              },
              send: 'Confirmar senha',
              return: 'Confirmar depois',
            },
          },
        },

        intermediatePetition: {
          modal: {
            title: 'Petição intermediária PJE',
            text: 'Documentos para envio',
            signedMessage: '<b>Info:</b> Apenas documentos assinados estão listados.',
            documentSelect: 'Os documentos selecionados serão enviados para petição intermediária via PJE.',
            info: 'Pelo menos um documento assinado precisa estar selecionado.',
            protocolNumber: 'Numero de protocolo',
            alertLoading: {
              title: 'Petição intermediária PJE',
              text: 'O tribunal receberá a petição e retornará um número de protocolo. Após isso, o(s) processo(s) irá(ão) para a sua caixa de arquivados. <strong>Atenção: Não feche essa janela até o envio ser finalizado.</strong>',
              results: {
                progress: 'petição enviada',
                oneProcess: 'ao enviar a petição via PJE.',
                manyProcedures: ' ao peticionar {{number}} processos.',
              },
            },
          },
        },

        subProcedure: {
          modal: {
            title: 'Criar subprocesso',
            disabled: 'A ação criar subprocesso encontra-se desabilitada para esta fase do(s) processo(s).',
            message: 'O andamento de criar subprocesso só é permitido um processo judicial por vez.',
            judicialOrder: 'Ofício judicial',
            paymentJudicialOrder: 'Ordem de pagamento judicial',
            requestCalculation: 'Pedido eletrônico de cálculos',
            judicialOrderModal: {
              title: 'Ofício judicial',
              officialData: '1. Dados do Ofício',
              fields: {
                originNumber: 'Número de origem',
                originNumberMask: 'XXXX/ANO-PJ',
                deadline: 'Prazo',
                deadlinePlaceholder: 'selecione uma data',
                tooltips: {
                  originNumber: 'Número de origem',
                  deadline: 'Determine o prazo do processo.',
                },
              },
              toggleText: 'Copiar as partes interessadas do processo principal no subprocesso',
              copyPartsTooltip: 'Selecione para copiar as partes interessadas do processo principal no subprocesso.',
              interested_parts: {
                header: {
                  interested_part: 'Parte interessada',
                  documentation: 'Documentação',
                  address: 'Endereço',
                },
                table: {
                  name: 'Nome:',
                  participation: 'Participação:',
                  indentity: 'RG:',
                  cpf: 'CPF:',
                  issuing_agency: 'Órgão emissor:',
                  public_place: 'Logradouro:',
                  district: 'Bairro:',
                  zip_code: 'CEP:',
                  number: 'Número:',
                  city: 'Cidade:',
                },
              },
              alert: {
                title: 'Deseja realmente criar um subprocesso de ofício judicial a partir do processo Nº {{processNumber}}',
                text: 'Os documentos anexados estarão nos detalhes do subprocesso',
              },
              alertWaiting: {
                title: 'Ofício judicial',
                alertText: {
                  prepare: 'Aguardando elaboração de documento para prosseguir com a sua criação de oficio judicial',
                  attach: 'Aguardando anexações de documento para prosseguir com a sua criação de oficio judicial',
                },
                text: 'O andamento "Criar subprocesso - Ofício judicial" prosseguirá após anexações.',
              },
              continue: 'Como deseja continuar?*',
              options: {
                elaborate_document: 'Elaborar documento de ofício judicial',
                attach: 'Anexar documento de ofício judicial',
              },
              buttons: {
                previousStep: 'Etapa anterior',
                nextStep: 'Próxima etapa',
                createSubProcess: 'Criar ofício judicial',
              },
              documents: {
                title: 'Documentos do oficio',
                tabTitle: '2. Documentos do ofício',
                text: 'O(s) documento(s) de ofício judicial elaborado estará anexado no subprocesso.',
              },
            },
            alertLoading: {
              title: 'Ofício judicial',
              text: 'O subprocesso de ofício judicial será criado e irá para sua caixa de entrada com o(s) documento(s) anexado(s)',
              results: {
                errorResult: 'Não foi possível juntar os processos',
                oneProcess: ' ao juntar 1 processo.',
                manyProcedures: ' ao juntar {{number}} processos.',
                progress: ' subprocessos(s) foi criado',
                attachFiles: 'anexar',
                prepareDocument: 'juntar',
              },
            },
          },
        },

        category: {
          categoryVerify: 'Classificação',
          create: {
            title: 'Cadastrar categoria de processo',
            selectCategory: 'Selecione uma classifcação',
            createText: 'Cadastrar nova categoria',
            categoryText: 'Categoria',
            categoryKey: 'Palavra chave',
            toasts: {
              successCreate: 'Sucesso ao cadastrar categoria',
              successEdit: 'Sucesso ao editar categoria',
              errorCreate: 'Erro ao cadastrar categoria',
              errorEdit: 'Erro ao editar categoria',
            },
          },
          update: {
            title: 'Alterar categoria de processo',
          },
          button: {
            create: 'Salvar categoria',
            update: 'Atualizar categoria',
          },
        },

        paymentOrder: {
          title: 'Ordem de pagamento judicial',
          modal: {
            tabs: {
              paymentOrder: {
                title: 'Dados do ofício',
                typeOrderPayment: 'Tipo de ordem de pagamento',
                options: {
                  smallValueRequest: 'Requisição de Pequeno Valor',
                  precatory: 'Precatório',
                },
                processNumber: 'Número de processo',
                processNumberTooltip: 'Número do processo judicial vinculado.',
                object: 'Objeto',
                objectTooltip: 'Objeto a que se refere o processo.',
                stick: 'Vara',
                placeholderStick: 'Selecione uma vara',
                stickTooltip: 'Vara responsável pelo processo.',
                dataScience: 'Data de Ciência do Mandado*',
                datePlaceholder: 'Selecione uma data',
                dateTooltip: 'Data em que se teve conhecimento do mandado.',
                placeholderObject: 'Selecione um objeto',
                tooltips: {
                  typeOrderPayment: 'Tipo de ordem de pagamento',
                },
              },
              listBeneficiaries: {
                title: 'Lista de Beneficiários',
                text: 'É necessário selecionar pelo menos um beneficiário e preencher os campos obrigatórios.',
                applicantsList: {
                  beneficiaryName: 'Nome',
                  cpf: 'CPF',
                  cnpj: 'CNPJ',
                  period: 'Período',
                  selectPeriod: 'Selecione o período',
                  value: 'Valor',
                  valuePlaceholder: 'R$ 0,00',
                  tooltips: {
                    beneficiaryName: 'Nome da pessoa que irá receber o benefício.',
                    date: 'Período em que o benefício será efetivado.',
                    value: 'Valor do benefício.',
                  },
                },
                addApplicants: {
                  title: 'Cadastrar Parte',
                  part: 'Parte',
                  gender: 'Sexo',
                  type: {
                    text: 'Tipo',
                    physicalPerson: 'Pessoa Física',
                    legalPerson: 'Pessoa Jurídica',
                  },
                  CPF: 'CPF',
                  CNPJ: 'CNPJ',
                  RG: 'RG',
                  corporateName: 'Razão social',
                  issuingAgency: 'Órgão emissor',
                  name: 'Nome',
                  sex: {
                    female: 'Feminino',
                    male: 'Masculino',
                  },
                  CEP: 'CEP',
                  publicPlace: 'Logradouro',
                  number: 'Número',
                  complement: 'Complemento',
                  neighborhood: 'Bairro',
                  county: 'Município',
                  buttons: {
                    buttonCreate: 'Adicionar mais requerentes',
                    cancel: 'Cancelar',
                    addPart: 'Cadastrar parte',
                  },
                },
              },
            },
            alertLoading: {
              title: 'Ordem de pagamento judicial',
              text: 'O subprocesso de Ordem de pagamento judicial será criado e irá para sua caixa de entrada com o(s) documento(s) anexado(s)',
              results: {
                errorResult: 'Não foi possível juntar os processos',
                oneProcess: 'ao criar 1 subprocesso.',
                progress: ' subprocessos(s) foi criado',
                manyProcedures: ' ao juntar documento(s) em {{number}} processos.',
              },
            },
            alertConfirmation: {
              title: 'Deseja realmente criar um subprocesso de ordem de pagamento judicial a partir do processo Nº {{processNumber}}',
              text: 'Os documentos anexados estarão nos detalhes do subprocesso.',
            },
            buttons: {
              text: 'Próxima etapa',
              previousStep: 'Etapa anterior',
              createCourtOrder: 'Criar ordem de pagamento',
            },
          },
        },

        eletronicCalculation: {
          title: 'Pedido eletrônico de cálculo',
          modal: {
            tabs: {
              orderData: '1. Dados do pedido',
              calculationData: '2. Dados do Cálculo',
            },

            orderDataContent: {
              processNumber: {
                generateProcessNumber: 'Gerar número de processo automaticamente',
                label: 'Número de processo',
              },
              copyParts: 'Copiar as partes interessadas do processo principal no subprocesso',
              buttonText: 'Próxima etapa',
            },
            calculationDataContent: {
              calculationType: 'Tipo de cálculo *',
              options: {
                mainCalculate: {
                  title: 'Cálculo do principal',
                  informations: 'Informações',
                  processNumber: 'Número de processo',
                  object: 'Objeto',
                  citationDate: 'Data da citação *',
                  datePlaceholder: 'Selecione um dia',
                  startingPoint: 'Marco inicial *',
                  finalPoint: 'Marco final *',
                  totalValueAuthorial: 'Valor total do cálculo autoral',
                  indexMonetaryCorrection: 'Índice para correção monetária *',
                  initialTermMonetary: 'Termo inicial correção monetária *',
                  indexForArrearsInterest: 'Indice para juros de mora *',
                  initialTermInterest: 'Termo inicial juros *',
                  finalUpdateTerm: 'Termo final da atualização *',
                  selectPlaceholder: 'Selecione uma opção',
                  other: 'Observação',
                  defaultValue: 'Sem classificação',
                  addApplicants: {
                    title: 'Cadastrar Parte',
                    part: 'Parte',
                    type: {
                      text: 'Tipo',
                      physicalPerson: 'Pessoa Física',
                      legalPerson: 'Pessoa Jurídica',
                    },
                    CPF: 'CPF',
                    CNPJ: 'CNPJ',
                    RG: 'RG',
                    corporateName: 'Razão social',
                    issuingAgency: 'Órgão emissor',
                    name: 'Nome',
                    sex: {
                      female: 'Feminino',
                      male: 'Masculino',
                    },
                    CEP: 'CEP',
                    publicPlace: 'Logradouro',
                    number: 'Número',
                    complement: 'Complemento',
                    neighborhood: 'Bairro',
                    county: 'Município',
                    buttons: {
                      buttonCreate: 'Adicionar mais requerentes',
                      cancel: 'Cancelar',
                      addPart: 'Cadastrar parte',
                    },
                  },
                  selects: {
                    monetary_indices: {
                      ipca: 'IPCA-E (STF - RE 870.947 - RG/SE)',
                      tr: 'TR (art. 1º-F, Lei 9.494/97, alterado pela Lei 11.960/09)',
                      ipca_selic: 'IPCA-E (STF - RE 870.947 - RG/SE) e, a partir de Dez/2021, SELIC (EC 113/2021)',
                      other_index: 'Outro',
                    },
                    monetary_term_indices: {
                      from_due_date: 'A partir do vencimento de cada parcela',
                      other_term_index: 'Outro',
                    },
                    moratorium_interest_indices: {
                      savings: 'Caderneta de poupança (art. 1º-F, Lei 9.494/97)',
                      selic: 'SELIC',
                      savings_selic: 'Caderneta de poupança (art. 1º-F, Lei 9494/97) e, a partir de Dez/2021, SELIC (EC 113/2021)',
                      other_moratorium_interest_index: 'Outro',
                    },
                    interest_initial_term: {
                      citation_date: 'Data da citação',
                      other_interest_initial_term: 'Outro',
                    },
                    update_end_terms: {
                      according_authorial: 'Conforme cálculo autoral',
                      other_update_end_term: 'Outro',
                    },
                  },
                  symbols: {
                    real: 'R$',
                  },
                  condemnationFees: {
                    label: 'Condenação em honorários',
                    yes: 'Sim',
                    no: 'Não',
                  },
                  updatingFees: 'Critérios para atualização dos honorários:',
                  updatingFeesPlaceholder: 'Escreva aqui os critérios...',
                  notesToConsider: 'Outras observações a serem consideradas:',
                  notesToConsiderPlaceholder: 'Escreva aqui outras observações...',
                  deliveryTime: 'Prazo de entrega*',
                  listOfApplicantsLabel: 'Lista de requerentes',
                  listOfApplicantsText: 'É necessário selecionar pelo menos um requerente.',
                  applicantsList: {
                    beneficiaryName: 'Nome do beneficiário',
                    document: 'Documento',
                  },
                  documentsLabel: 'Documentos',
                  documentsText: 'É necessário anexar um documentos que explique as regras de cálculo para finalizar o pedido.',
                  attachAndTramit: 'Anexar documentos e tramitar para Coordenador em Perícia Contábil.',
                },
                attorneyCalculate: {
                  title: 'Cálculo do advogado',
                  informations: 'Informações',
                  warningSolicitationOfCalculate: 'Esta solicitação de cálculo é exclusiva para os casos de execução apenasdos honorários.',
                  calculateDetails: 'Detalhes do cálculo',
                  calculateDetailsPlaceholder: 'Escreva aqui os detalhes do cálculo...',
                  deliveryTime: 'Prazo para entrega',
                },
                buttons: {
                  previusStep: 'Etapa anterior',
                  createEletronicOrder: 'Criar pedido eletrônico',
                },
              },
            },
            alertConfirmation: {
              title: 'Deseja realmente criar um subprocesso de pedido eletrônico de cálculo a partir do processo Nº {{processNumber}}?',
              text: 'Os documentos anexados estarão nos detalhes do subprocesso.',
            },
            tooltips: {
              generateProcessNumberTooltip: 'Selecione para que o número do processo seja gerado automaticamente.',
              copyPartsTooltip: 'Selecione para copiar as partes interessadas do processo principal no subprocesso.',
              calculationTypeTooltip: 'Determine o método de cálculo a ser utilizado.',
              processNumberTooltip: 'Número do processo judicial vinculado.',
              objectTooltip: 'Objeto a que se refere o processo.',
              citationDateTooltip: 'Data de convocação do réu para fazer parte do processo.',
              startingPointTooltip: 'Intervalo inicial do cálculo.',
              finalPointTooltip: 'Intervalo final do cálculo.',
              totalValueAuthorialTooltip: 'Valor total do cálculo autoral.',
              indexMonetaryCorrectionTooltip: 'Selecione o índice a ser utilizado para cálculo da correção monetária.',
              initialTermMonetaryTooltip: 'Método a ser utilizado para cálculo da correção monetária.',
              indexForArrearsInterestTooltip: 'Selecione o índice a ser utilizado para cálculo de juros de mora.',
              initialTermInterestTooltip: 'Método a ser utilizado para cálculo de juros.',
              finalUpdateTermTooltip: 'Método a ser utilizado para cálculo final da atualização.',
              condemnationFeesTooltip: 'Determine se deverá ocorrer condenação em honorários (pagamentos advocatícios).',
              updatingFeesTooltip: 'Descreva os critérios a serem utilizados para a atualização dos honorários.',
              notesToConsiderTooltip: 'Inclua outras observações a serem consideradas.',
              deliveryTimeTooltip: 'Prazo para entrega.',
              listOfApplicantsLabelTooltip: 'Descreva o método de cálculo utilizado pelo advogado.',
              calculateDetailsTooltip: 'Descreva o método de cálculo utilizado pelo advogado.',
            },
            alertLoading: {
              title: 'Pedido eletrônico de cálculo',
              text: 'O subprocesso de Pedido eletrônico de cálculo será criado e irá para sua caixa de entrada com o(s) documento(s) anexado(s)',
              results: {
                oneProcess: 'ao criar 1 subprocesso.',
                progress: ' subprocessos(s) foi criado',
              },
            },

          },
          validation: {
            ending_point: 'Marco final não pode ser anterior ao marco inicial',
          },
        },

        joinDocuments: {
          title: 'Juntar documentos',
          disabled: 'A ação juntar documentos encontra-se desabilitada para esta fase do(s) processo(s).',
          modal: {
            submit: 'Juntar documentos',
            alertConfirmation: {
              title: 'Deseja realmente juntar {{count}} documento(s) no processo Nº {{number}}?',
              titleManyProcedures: 'Deseja realmente juntar {{count}} documento(s) nos {{number}} processos?',
              text: 'Os {{count}} documento(s) serão anexados no processo Nº {{number}} e seu respectivo responsável será notificado.',
              textManyProcedures: 'Os {{count}} documento(s) serão anexados nos {{number}} processos e seus respectivos responsáveis serão notificados.',
            },
            alertLoading: {
              text: 'Documentos serão anexados aos processos e seus respectivos responsáveis serão notificados.',
              results: {
                progress: ' processo(s) com documento(s) juntados(s)',
                oneProcess: ' ao juntar documento(s) em 1 processo.',
                manyProcedures: ' ao juntar documento(s) em {{number}} processos.',
              },
            },
          },
        },

        editProcedure: {
          title: 'Editar cadastro',
          disabled: 'A ação editar cadastro encontra-se desabilitada para esta fase do(s) processo(s).',
        },

        attach: {
          title: 'Apensar',
          attachProcedure: 'Apensar processo(s)',
          unattachProcedure: 'Desapensar processo(s)',
          disabled: 'A ação desapensar encontra-se desabilitada para uso em massa.',
        },

        court: {
          title: 'Tribunal',
          initialPetition: 'Petição inicial PJE',
          intermediatePetition: 'Petição intermediária PJE',
        },

        spu: {
          title: 'SPU',
          sendOficialLetter: 'Enviar via SPU',
          sendSucesss: 'Sucesso ao enviar via SPU',
          registrationOficialLetter: 'Cadastrar Ofício Eletrônico - PROJUD',
          tramitOfficialLetter: 'Encaminhar processo SPU',
          updateOficialLetter: 'Atualizar ofício judicial',
          spuData: 'Verificar dados de ofício eletrônico',
          modal: {
            headers: {
              destined: 'Destino',
              copy: 'Com cópia',
            },
            attachments: {
              text: 'Os anexos selecionados serão enviados via SPU.',
              message: 'Pelo menos um documento assinado precisa estar selecionado.',
              error: 'Ops! Nenhum documento assinado está selecionado',
            },
            form: {
              institution: 'Instituição de destino',
              field: 'Área do destino',
              priority: 'Prioridade do SPU',
            },
            documentData: {
              origin: 'Número de origem',
              deadline: 'Prazo',
              priority: 'Prioridade do SPU*',
              institution: 'Instituição de destino*',
              field: 'Área do destino*',
              description: 'Corpo do processo*',
              descriptionPlaceholder: 'Digite aqui uma pequena descrição do conteúdo do processo.',
              institutionPlaceholder: 'Selecione a instituição',
              fieldPlaceholder: 'Selecione a área',
              priorityPlaceholder: 'Selecione a prioridade',
              tooltip: {
                deadline: 'Este é o prazo',
                priority: 'Selecione a prioridade do ofício.',
                institution: 'Selecione qual a instituição de destino ao qual o processo deve ser encaminhado.',
                field: 'As opções de área de destino são baseadas na instituição que foi selecionada no campo anterior. Informe qual a área de destino do ofício.',
              },
              observation: {
                label: 'Comentários',
                placeholder: 'Digite aqui uma observação',
              },
            },
            partData: {
              part: 'Tipo do Manisfestante*',
              partPlaceholder: 'Selecione o tipo',
              name: 'Nome*',
              namePlaceholder: 'Digite aqui o nome do manifestante',
              responsible: 'Responsável',
              responsiblePlaceholder: 'Digite aqui o nome do responsável',
              organization: 'Organização',
              organizationPlaceholder: 'Digite aqui o nome da organização',
              cpf: 'CPF*',
              cpfPlaceholder: '000.000.000-00',
              cnpj: 'CNPJ*',
              cnpjPlaceholder: '00.000.000/0000-00',
              gender: 'Gênero*',
              genderPlaceholder: 'Selecione o gênero',
              male: 'Masculino',
              female: 'Feminino',
              tooltip: {
                part: 'Escolha o tipo de manifestante.',
                name: 'Informe o nome completo do manifestante.',
                responsible: 'Informe o nome completo do responsável pela manifestação.',
                organization: 'Informe qual a organização a qual o manifestante está vinculado.',
              },
            },
            partAddress: {
              street: 'Logradouro',
              streetPlaceholder: 'Nome da rua',
              number: 'Número',
              complement: 'Complemento',
              cep: 'CEP',
              cepPlaceholder: '00.000-000',
              state: 'Estado*',
              statePlaceholder: 'Selecione o estado',
              city: 'Cidade*',
              cityPlaceholder: 'Selecione a cidade',
              neighborhood: 'Bairro*',
              neighborhoodPlaceholder: 'Selecione o bairro',
            },
            partContact: {
              homePhone: 'Telefone residencial',
              cellPhone: 'Telefone celular',
              commercialPhone: 'Telefone comercial',
              phonePlaceholder: '(00) 0000-0000',
              email: 'Email',
              observation: 'Observações',
              observationPlaceholder: 'Digite aqui uma observação',
            },
            alertLoading: {
              loadingMessage: 'O setor <strong>{{field}}</strong> da instituição <strong>{{institution}}</strong> receberá o ofício e irá para sua caixa de arquivados.',
              results: {
                progress: 'ofício enviados',
                oneProcess: ' ao enviar ofício',
                manyProcedures: ' ao enviar ofícios',
              },
            },
          },
          tabs: {
            attachments: '1. Anexos',
            crudData: '2. Dados de cadastro',
            documentData: 'Dados do ofício',
            partData: 'Dados do Manifestante',
          },
          send: {
            title: 'Deseja realmente enviar esse ofício judicial via SPU com esses dados?',
            message: 'O ofício será enviado para a instituição <strong>{{institution}}</strong>, no setor <strong>{{field}}</strong>, com prazo para a data <strong>{{deadline}}</strong>. Você confirma estas informações?',
          },
          registration: {
            title: 'Deseja realmente cadastrar esses dados para SPU?',
            message: 'O ofício será cadastrado e deverá ser enviado para a instituição <strong>{{institution}}</strong>, no setor <strong>{{field}}</strong>, com prazo para a data <strong>{{deadline}}</strong>. Você confirma o cadastro destas informações?',
          },
          validators: {
            cpf: 'CPF inválido',
            cnpj: 'CNPJ inválido',
            email: 'Email inválido',
          },
          error: 'O serviço SPU está temporariamente indisponível. Por favor, tente novamente mais tarde.',
          nextStep: 'Próxima etapa',
          prevStep: 'Etapa anterior',
          sendSpu: 'Enviar ofício judicial',
          createPaymentOrder: 'Criar ordem de pagamento',
          disabledButton: 'O andamento encontra-se desabilitado pois só é possível com a seleção de apenas um subprocesso de ofício judicial com pelo menos um documento assinado.',
        },

        tramitSpu: {
          modal: {
            alertLoading: {
              loadingMessage: 'O encaminhamento está sendo realizado.',
              results: {
                progress: 'encaminhado',
                oneProcess: ' ao realizar o encaminhamento',
                manyProcedures: ' ao realizar os encaminhamentos',
              },
            },
          },
        },

        registrationSpu: {
          modal: {
            alertLoading: {
              loadingMessage: 'O cadastro está sendo realizado.',
              results: {
                progress: 'cadastrado',
                oneProcess: ' ao realizar o cadastro',
                manyProcedures: ' ao realizar os cadastros',
              },
            },
          },
        },

        ipm: {
          title: 'IPM',
          requestDueDiligence: 'Solicitar diligência',
          forwardIPM: {
            text: 'Encaminhar para IPM',
            modal: {
              tabs: {
                verifyAttach: '1. Verificação de anexos',
                verifySignatures: '2. Verificação de assinaturas',
                description: '3. Descrição',
                preview: ' 4. Pré-visualização',
              },

              nextStage: {
                text: 'Próxima etapa',
              },

              verify: {
                info: 'Para encaminhar ao IPM é preciso que cada processo contenha pelo menos dois anexos:',
                processAdministrative: '1. anexo do tipo processo administrativo.',
                retirement: '2. anexo do tipo aposentadoria.',
                verifyAttach: 'Verificando se há anexos',
                verifySignature: 'Verificando se há assinaturas',
                verifyAttachProcedures: '{{proceduresVerify}} de {{proceduresLength}} processo{{plural}} verificado{{plural}}',
                verifySignatureProcedures: '{{proceduresVerify}} de {{proceduresLength}} processo{{plural}} assinado{{plural}}',

                result: {
                  verifyAttach: {
                    warning: {
                      plural: 'Ops! {{totalWithoutDocument}} de {{length}} processos não possuem anexo(s).',
                      singular: 'Ops! {{totalWithoutDocument}} de {{length}} processo não possui anexo(s).',
                    },
                    continue: '<bold>Prosseguir</bold> com os {{length}} processos que já possuem anexos',
                    allright: {
                      plural: 'Tudo certo! os {{length}} processos possuem anexos.',
                      singular: 'Tudo certo! {{length}} processo possui anexo(s).',
                    },
                  },

                  verifySignature: {
                    warning: {
                      plural: 'Ops! {{totalWithoutDocument}} de {{length}} processos não possuem assinaturas.',
                      singular: 'Ops! {{totalWithoutDocument}} de {{length}} processo não possui assinatura',
                    },
                    continue: '<bold>Prosseguir</bold> com os {{length}} processos que já possuem assinatura',
                    allright: {
                      plural: 'Tudo certo! os {{length}} processos possuem assinaturas.',
                      singular: 'Tudo certo! {{length}} processo possui assinatura.',
                    },
                  },
                },
              },

              description: {
                title: 'Descrição',
                default: 'Descrição Geral',
                personalize: {
                  title: 'Descrição personalizada (opcional)',
                  subTitle: 'Clique no processo que deseja personalizar a descrição:',
                },
                typeDispatch: {
                  title: 'Tipo de despacho',
                  deferment: 'Deferimento',
                  deferralReservation: 'Deferimento com ressalva',
                  rejection: 'Indeferimento',
                  diligence: 'Diligência',
                },

                legalAnalysis: {
                  title: 'Análise jurídica',
                  placeholder: 'Digite uma breve descrição da análise dos processos.',
                },

                report: {
                  title: 'Relatório',
                  placeholder: 'Digite uma breve descrição sobre do que se trata o despacho.',
                },

                conclusion: {
                  title: 'Conclusão',
                  placeholder: 'Digite uma breve descrição sobre as conclusões e pendências.',
                },

                question: {
                  title: 'Aplicar a mesma descrição para todos os processos selecionados?',
                  yes: 'Sim, a descrição deve se repetir em todos os processos',
                  no: 'Não, quero personalizar a descrição em alguns processos',
                },
              },

              verifySignatures: {
                info: 'É preciso que todos os documentos estejam assinados antes de encaminhar para IPM.',
              },

              preview: {
                attachments: {
                  title: 'Anexos',
                  label: 'Documentos anexados e assinados nos processos:',
                },
              },

              button: {
                previous: 'Etapa anterior',
                forwardIpm: 'Ecaminhar para IPM',
              },
            },
          },
        },

        digitalFolder: {
          header: {
            flux: 'Processo (Fluxo):',
            individual: 'Responsavel Atual:',
          },
          modal: {
            title: 'Download de documentos',
            subtitle: 'Como deseja salvar os documentos?',
            unique: 'Arquivo único',
            multiple: 'Um arquivo para cada documento',
          },
          page: 'Página',
          title: 'Pasta Digital',
          pje: 'Pasta Digital do PJE',
          markAll: 'Marcar todos',
          unmarkAll: 'Desmarcar todos',
          download: 'Download',
        },

        disabled: 'A ação {{name}} encontra-se desabilitada para esta fase do(s) processo(s).',
        working: 'A ação está em desenvolvimento e logo estará disponível para utilização.',
      },

      searchBar: {
        placeholder: 'Pesquise por número de processo, procurador, ...',
        placeholderShort: 'Pesquise...',
      },

      boxesSelect: {
        inbox: 'Caixa de entrada',
        outbox: 'Caixa de saída',
        sharebox: 'Caixa compartilhada',
        archive: 'Arquivados',
        citation_intimation: 'Citações/Intimações',
        total: 'Total',
        unread: 'Não lidos',
        archiveMonth: 'Arquivados do mês',
        outboxMonth: 'Saídas do mês',
      },

      markersSelect: {
        title: 'Marcadores',
        notFound: 'Marcador não encontrado',
        types: {
          priority: 'Prioridade',
          pendency: 'Pendência',
          custom: '',
        },
        clean: 'Limpar marcadores',
        total: 'Total',
        count: 'Nº de processos encontrados',
      },

      filterTabs: {
        unreadProcedure: '{{value}} novo{{plural}}',
        allTabs: 'Todos os processos da caixa',
      },

      markers: {
        title: 'Marcadores',
        priority: 'Prioridade',
        pendency: 'Pendências',
        custom: 'Personalizados',
        addButton: 'Adicionar marcador',
        public: 'Público',
        private: 'Privado',
        placeholder: 'Buscar por marcador...',
        viewMore: 'Ver mais',
        newMarker: 'Novo marcador',
        alert: 'O marcador não pode ser editado/excluído, pois contém processos vinculados. Remova o marcador dos processos para editar/excluir.',
        message: {
          public: 'Ainda não há marcadores personalizados públicos criados.',
          private: 'Ainda não há marcadores personalizados privados criados.',
        },
        link: {
          public: 'O que são marcadores personalizados públicos?',
          private: 'O que são marcadores personalizados privados?',
        },
      },
      calculationData: '2. Dados do Cálculo',
      modalMarker: {
        titleCreate: 'Criar novo marcador',
        titleEdit: 'Editar marcador',
        publicExhibition: 'Exibição pública',
        warningText: 'O nome deve conter no máximo 27 caracteres.',
        cancel: 'Cancelar',
        save: 'Salvar',
        input: {
          label: 'Nome do marcador',
          placeholdolder: 'Digite o nome do marcador',
        },
        delete: {
          title: 'Deseja realmente excluir esse marcador?',
          subtitle: 'O marcador "{{marker}}" será excluído do sistema e desvinculado de todos os processos.',
          confirm: 'Excluir',
          success: 'O marcador "{{marker}}" foi excluído com sucesso.',
          error: 'Erro ao excluir o marcador "{{marker}}".',
        },
        color: 'Cor do marcador *',
        tooltipTitle: 'Ao desabilitar essa chave, o marcador será visível apenas para você.',
      },

      toastMarker: {
        success: {
          create: 'Marcador {{marker_name}} criado com sucesso.',
          edit: 'Marcador {{marker_name}} editado com sucesso.',
        },
        error: {
          create: 'Não foi possivel criar o marcador {{marker_name}}',
          edit: 'Não foi possivel editar o marcador {{marker_name}}',
        },
      },

      notes: {
        myNotes: 'minhas',
        otherNotes: 'de outros colaboradores',
        note: 'nota',
        notes: 'notas',
        newNote: 'Nova anotação',
      },

      observations: {
        tramitObservations: 'Obs. de trâmite',
        hasObservations: 'Por: ',
        noOne: 'Não há observações para o processo',
        createdBy: 'Tramitado por {{individual_name}} em {{tramit_date}}',
        markAsFinished: 'Marcar como lido',
        empty: 'Não há observação',
        done: 'Lida',
      },

      deadline: {
        label: 'Prazos',
      },

      sortMenu: {
        title: 'Ordenar por:',
        mostRecent: 'Mais recentes na caixa',
        older: 'Mais antigos na caixa',
        mostUpdatedAt: 'Atividade mais recente',
        olderUpdatedAt: 'Atividade mais antiga',
        mostRecentCreated: 'Mais recente (criação)',
        olderCreated: 'Mais antigo (criação)',
        mostRecentDeadline: 'Prazo mais distante',
        olderDeadline: 'Prazo mais próximo',
        unread: 'Não lidos',
        read: 'Lidos',
        lowerValue: 'Menor valor',
        highestValue: 'Maior valor',
      },

      markerMenu: {
        low: 'Baixa',
        normal: 'Média',
        high: 'Alta',
        no_pending: 'Sem pendência',
        information: 'Informações',
        documents: 'Documentos',
        archiving: 'Arquivamento',
      },

      procedureDeadline: {
        title: 'Informações do prazo',
        deadline: 'Prazo:',
        cancel: 'Cancelar',
        reason: 'Motivo',
        reasonPlaceholder: 'Selecione um motivo',
        inputTitle: 'Título',
        inputTitlePlaceholder: 'Digite um título',
        description: 'Descrição',
        descriptionPlaceholder: 'Digite aqui uma descrição',
        deadlinePlaceholder: 'Selecione um dia',
        submit: 'Salvar',
        delete: 'Excluir prazo',
        update: 'Atualizar',
        error: 'Erro ao atualizar prazo',
        tooltip: '<bold>{{reason}}</bold> \n {{title}} \n {{description}}',
        public: 'Exibição pública',
        private: 'Exibição privada',
        tooltipVisibility: 'Ao desabilitar essa chave, o evento será visível apenas para você.',
        attorney: 'Procurador(a)',
        attorneyPlaceholder: 'Busque por nome',
        court: 'Vara',
        courtPlaceholder: 'Busque por vara',
        dateError: 'Data inválida. Selecione uma data de hoje em diante.',
        weekendMessage: 'Prazo válido apenas para dias úteis da semana',
        updateMessage: {
          success: 'Prazo atualizado com sucesso',
        },
        noProcedureNumber: 'Sem número de cadastro',
        hearingError: 'Não é possível criar prazo em processos sem número',
      },

      paginationMenu: {
        twenty: '20 por página',
        thirty: '30 por página',
        fifty: '50 por página',
        nextPage: 'Próxima página',
        prevPage: 'Página anterior',
        nextLastPage: 'Última página',
        prevFirstPage: 'Primeira página',
      },

      pje: {
        protocolDate: 'Processo(s) protocolado(s) no PJE em {{ date }}',
        info: 'Esta consulta foi realizada no site',
        link: 'pje.tjce.jus.br',
        table: {
          protocol: 'Processo protocolado',
          movimentation: 'Última movimentação',
          movimentationDate: 'Data da movimentação',
          documents: 'Documentos protocolados',
          error: 'Um erro inesperado ocorreu ao buscar as informações de peticionamento',
        },
        file: 'Arquivo(s)',
      },

      filterAdvanced: {
        filter: 'Filtrar',
        clearFilter: 'Limpar filtros',
        label: 'Filtro avançado',
        title: 'Filtrar em <bold>{{ box }}</bold> por:',
        data: 'Dados',
        schedule: 'Agenda',
        details: {
          title: 'Detalhes',
          notes: 'Anotações?',
          observations: 'Observações?',
          resume: 'Resumo?',
        },
        boxesSelect: {
          inbox: 'caixa de entrada',
          outbox: 'caixa de saída',
          sharedbox: 'caixa compartilhada',
          archivedbox: 'caixa de arquivados',
          citationIntimation: 'caixa de citações/intimações',
        },
        markers: {
          title: 'Marcadores',
          priority: {
            title: 'Prioridade',
            tooltip: 'Busque processos que contenham um ou mais marcadores de prioridade selecionados.',
          },
          pendencies: {
            title: 'Pendências',
            tooltip: 'Busque processos que contenham um ou mais marcadores de pendências selecionados.',
          },
          public_custom: {
            title: 'Personalizados Públicos',
            tooltip: 'Busque processos que contenham um ou mais marcadores personalizados que sejam públicos.',
          },
          private_custom: {
            title: 'Personalizados Privados',
            tooltip: 'Busque processos que contenham um ou mais marcadores personalizados que você criou.',
          },
          irrelevant: 'Irrelevante',
        },
        lastSteps: {
          title: 'Último Andamento',
          placeholder: 'Todos os andamentos',
        },
        procedureNumber: {
          title: 'Nº do processo',
          placeholder: 'ex: 000000000000',
        },
        responsible: {
          title: 'Responsável atual',
          placeholder: 'Digite o nome',
        },
        wasSeen: {
          title: 'Processo lido?',
        },
        timeBox: {
          title: 'Tempo na Caixa',
          deadline: 'Com prazo definido?',
          select: {
            irrelevant: 'Irrelevante',
            less_than_one_month: 'Menos de 1 mês',
            more_than_one_month: 'Mais de 1 mês',
            more_than_one_year: 'Mais de 1 ano',
            more_than_two_years: 'Mais de 2 anos',
            more_than_three_years: 'Mais de 3 anos',
          },
        },
        procedureCreatedAt: {
          title: 'Criado Em',
          placeholder: 'Dia ou Período',
          clear: 'Limpar Filtro',
          from: 'A partir de:',
          until: 'Até:',
        },
        hasCdas: {
          title: 'CDAs vinculadas?',
          yes: 'Sim',
          no: 'Não',
          irrelevant: 'Irrelevante',
        },
        statusProcedure: {
          title: 'Situação do processo',
          placeholder: 'Qualquer situação',
          progress: 'Em andamento',
          finished: 'Finalizado',
          archived: 'Arquivado',
        },
        procedureValue: {
          title: 'Valor',
          placeholder: 'Todos os Valores',
          select: {
            option1: 'Abaixo de R$ 6.000,00',
            option2: 'De R$ 6.000,00 a $ 30.000,00',
            option3: 'De R$ 30.000,00 a $ 50.000,00',
            option4: 'Acima de R$ 50.000,00',
          },
        },
        municipalPart: {
          title: 'Parte do Município',
          executed: 'Executado',
          bidder: 'Embargante',
          creditor: 'Exequente',
        },
        fluxes: {
          title: 'Fluxo',
          placeholder: 'Todos os fluxos',
          message: {
            emptyList: 'Ainda não há fluxos de processos em sua caixa. Quando houver, eles aparecerão aqui.',
            label: 'O que são fluxos?',
          },
        },
        subjects: {
          title: 'Assuntos',
          placeholder: 'Todos os assuntos',
        },
        fields: {
          title: 'Área atual',
          placeholder: 'Todas as áreas',
          message: {
            emptyList: 'Ainda não há áreas de processos em sua caixa. Quando houver, eles aparecerão aqui.',
            label: 'O que são áreas?',
          },
        },
        interestedPartDocument: {
          title: 'Documento da parte',
          placeholder: 'Digite o CPF, RG ou CNPJ',
        },
        tooltips: {
          last_step: 'Busque processos de acordo com a última atividade realizada.',
          flux: 'Fluxos são os tipos de processos. Busque por um ou mais.',
          procedure: 'Busque por um ou mais processos.',
          interested_part: 'Envolvido(a) no processo. Busque por uma ou mais.',
          field: 'Selecione a área da PGM onde o processo que deseja se encontra.',
          municipal_part: '<strong>Executado:</strong> é a parte que está sendo processada. <br /><strong>Embargante:</strong> é a pessoa que embarga, ao qual apresenta embargo de declaração ou embargo à execução. <br /><strong>Exequente:</strong> é quem entra com o processo, sendo considerado o autor da ação.',
          value: 'Busque pela faixa de valor dos processos.',
          individual: 'Selecione o colaborador que está responsável pelo processo que deseja nesse momento.',
          linked_cdas: 'Busque por processos que tenham vínculo com uma ou mais Certidões de Dívida Ativa (CDA).',
          situation: 'Busque por processos que estejam na situação desejada.',
          timebox: 'Quantidade de tempo que o processo está na sua caixa.',
          deadline: 'Caso deseje buscar processos com prazo definido, clique em sim.',
          notes: 'Caso deseje buscar processos com anotações, clique em sim.',
          observations: 'Caso deseje buscar processos com observações, clique em sim.',
          resume: 'Caso deseje buscar processos com resumo, clique em sim.',
          interestedPartDocument: 'Documentos do(a) envolvido(a) no processo.',
          wasSeen: 'Busque por processos que já foram ou não vistos.',
          procedureCreatedAt: 'Selecione a data ou o período de criação do processo.',
        },
      },

      selectionBoxMenu: {
        title: 'Selecionar',
        all: 'Todos',
        allInBox: 'Todos',
        allInPage: 'Todos na página',
        none: 'Nenhum',
        read: 'Lidos',
        unread: 'Não Lidos',
        message: 'Selecionando todos os processos da {{box}}',
        text: 'Aguarde até todos os processos estarem selecionados',
      },

      quickSearch: {
        title: 'Pesquisa rápida aplicada na <bold>caixa de entrada</bold>',
        label: 'Nº de processos encontrados:',
        total: 'Total:',
        empty: 'Nenhum processo foi encontrado',
        loading: 'Pesquisando/buscando, aguarde...',
        clean: 'Limpar filtros',
      },
      dropFile: {
        buttom: 'Adicione múltiplos arquivos aqui',
        description: 'ou solte-os nessa página',
      },
    },
    general: {
      concluded: 'Concluído',
      confirm: 'Confirmar',
      edit: 'Editar',
      destroy: 'Excluir',
      allFluxes: 'Todos os fluxos',
      requeried: 'Campo obrigatório',
      noOptions: 'Sem resultados',
      emptyOptions: 'Sem opções',
      changePassword: 'Alterar senha',
      importA1: 'Certificado A1',
      yes: 'Sim',
      no: 'Não',
      cancel: 'Cancelar',
      tryAgain: 'Tentar novamente',
      refresh: 'Atualizar',
      save: 'Salvar',
      dashboard: 'Meu dashboard',
      cpf: 'CPF inválido',
      cnpj: 'CNPJ inválido',
      cep: 'CEP inválido',
      cepError: 'Não foi possivel obter o endereço',
      date: 'A data deve ser maior ou igual à data de hoje',
      invalidDate: 'Data inválida',
      message: {
        emptyList: 'Nenhum processo foi encontrado na sua caixa.',
        label: 'Aplique sua pesquisa no Acervo da PGM',

      },
      password: {
        current: 'Senha atual',
        new: 'Nova senha',
        newPlaceholder: 'Digite a nova senha',
        confirmation: 'Confirmação',
        confirmationPlaceholder: 'Confirme a nova senha',
        validation: 'Não corresponde com a nova senha',
        requeried: 'Campo obrigatório',
        success: 'Senha alterada com sucesso',
        error: 'Erro ao alterar senha. Verifique se a senha atual está correta.',
      },
      login: {
        password: 'Senha',
        passwordPlaceholder: 'Sua senha',
        username: 'Usuário',
        usernamePlaceholder: 'Seu e-mail ou CPF',
        signIn: 'Acessar',
        forgotPassword: 'Esqueceu a senha?',
        contact: 'Dúvidas? <a href="https://wa.me/558589260327?agilis=true" target="_blank">Fale conosco</a>',
        footer: 'Desenvolvido pela Procuradoria Geral do Município de Fortaleza (PGM). 2022.',
        slogan: 'Sua procuradoria digital, simples e ágil',
        emptyFields: 'Digite um usuário e senha',
        forgotPasswordModal: {
          emailInstructions: 'Você receberá um e-mail com instruções para troca de senha.',
          email: 'Digite seu e-mail',
          emailPlaceholder: 'Seu e-mail ou usuário',
          submit: 'Enviar',
          error: 'Falha ao buscar o usuário',
        },
        recoverPassword: {
          title: 'Troque sua senha',
          newPassword: 'Nova senha',
          newPasswordPlaceholder: 'Digite sua nova senha',
          confirmPassword: 'Confirmar senha',
          submit: 'Salvar nova senha',
          differentPassword: 'As senhas devem ser iguais',
          emailSend: 'Aguarde, em instantes você irá receber o e-mail para troca de senha.',
          error: 'Algo deu errado',
          success: 'Senha alterada com sucesso, <a href="/">clique aqui</a> para fazer o login',
        },
      },
      toast: {
        warning: 'Aviso',
        info: 'Info',
        success: 'Sucesso',
        error: 'Erro',
      },
      logout: {
        label: 'Sair do Ágilis',
        text: 'Deseja realmente sair do Ágilis',
      },
      changePicture: {
        title: 'Alterar foto do perfil',
        text: 'Uma foto no seu perfil ajuda a outros colaboradores a reconhecerem você.',
        add: 'Adicionar foto de perfil',
        change: 'Mudar foto de perfil',
        remove: 'Remover foto de perfil',
        deleteError: 'Erro ao remover foto de perfil. Tente novamente mais tarde.',
        deleteSuccess: 'Foto de perfil removida com sucesso',
        changeError: 'Erro ao alterar foto de perfil. Tente novamente mais tarde.',
        changeSuccess: 'Foto de perfil alterada com sucesso',
      },
      modalImportA1: {
        title: 'Cadastro do Certificado A1',
        text: 'Anexe o Certificado Digital A1 com as extensões ".p12" ou ".pfx" para cadastrar',
        updatedAt: 'Cadastro realizado dia {{date}}',
        toast: 'Você não possui Certificado cadastrado no sistema',
        link: {
          add: 'Anexar arquivo',
          update: 'Substituir arquivo',
        },
        add: {
          success: 'Certificado A1 cadastrado.',
          error: 'Erro ao cadastrar Certificado A1. Tente novamente mais tarde.',
        },
        download: {
          success: 'Download de Certificado A1 feito com sucesso.',
          error: 'Erro ao baixar certificado A1.',
        },
        delete: {
          success: 'Certificado A1 excluído com sucesso.',
          error: 'Erro ao excluír certificado A1.',
        },
        submit: 'Cadastrar Certificado',
        cert: {
          valid: 'Válido por {{days}} dia(s)',
          expired: 'Vencido há {{days}} dia(s)',
          error: '<b>Certificado A1 vencido:</b> Atualize seu cadastro.',
        },
        validateDate: 'Validade do certificado: {{date}}',
      },
    },
    topbar: {
      rightItems: {
        user_name: 'Nome do Usuário',
      },
    },
    sidebar: {
      modules: {
        title: 'Módulos',
        systems: 'Outros sistemas',
        digital_process: {
          title: 'Processos digitais',
          reports: {
            title: 'Relatórios',
            dashboard: 'Dashboard',
            dashboard_health: 'Dashboard (saúde)',
            distribution: 'Distribuição',
            productivity: 'Produtividade',
            petition: 'Peticionamento',
            audience: 'Audiências',
            search_cabinet: 'Buscar no gabinete',
            search_judicial: 'Buscar na Judicial',
            court_orders: 'Ordens judiciais',
            accounting_calculations: 'Cálculos Contábeis',
            official_letter: 'Ofícios Judiciais',
          },
          home: 'Homescreen',
          procedure_box: 'Caixa de processos',
          procedure_collection: 'Acervo de processos',
        },
        active_debt: {
          title: 'Dívida ativa',
          requeriment: {
            title: 'Requerimento',
            inscribe: 'Inscrever requerimento',
            request_analysis: 'Solicitar análise',
            register: 'Cadastrar requerimento',
            register_edit_contributor: 'Cadastrar/editar contribuinte',
          },
          inscription: {
            title: 'Inscrições',
            consult_advanced_debit: 'Consultar avançada de débito',
            extract_debit: 'Extrato de débitos',
            statement_responsibility: 'Emitir declaração de responsabilidade',
            extinguish_suspend_credit: 'Extinguir/suspender crédito',
            ownership_change: 'Alteração de titularidade',
            suspension_extinction_requests: 'Solicitações de suspensão/extinção',
            migration_registered_debts: 'Migração de dívidas inscritas',
            approve_migration_registered_debts: 'Aprovar migração de dívidas inscritas',
            retroactive_calculation: 'Cálculo retroativo',
          },
          protest: {
            title: 'Protesto',
            request_protest: 'Solicitar protesto',
            abandonment_cancellation: 'Desistência/cancelamento',
          },
          judgment: {
            title: 'Ajuizamento',
            request: 'Solicitar ajuizamento',
            forward: 'Encaminhar ajuizamento',
          },
          management: {
            title: 'Gestão',
            active_debt_diagnosis: 'Diagnóstico da dívida ativa',
            active_debt_diagnosis_phase: 'Diagnóstico da dívida ativa por fase',
            active_debt_diagnosis_range: 'Diagnóstico da dívida ativa por faixa de valor',
            geolocation_contributors: 'Geolocalização dos contribuintes',
            geolocation_contributors_regions: 'Geolocalização dos contribuintes por bairros/regionais',
            overview_municipal_active_debt: 'Visão geral da dívida ativa municipal',
            overview_prodat: 'Visão geral da dívida ativa (PRODAT)',
            overview_installment_management: 'Visão gerencial de parcelamentos',
            collection_management: 'Gestão da arrecadação',
            overview_judgment: 'Visão gerencial do ajuizamento',
            overview_protest: 'Visão gerencial do protesto',
            evolution_debt_stock: 'Evolução do estoque da dívida',
            overview_phases_debt_inscriptions: 'Visão das fases das inscrições dos débitos',
          },
          reports: {
            extract_inscriptions: 'Extrato de inscrições',
            general_collection: 'Arrecadação geral',
            tax_collection: 'Arrecadação por tributo',
            collection_charges: 'Arrecadação de encargos',
            management_view_active_debt: 'Visão gerencial da dívida ativa',
            manage_installments_agreements: 'Gerenciar parcelamentos/acordos',
            report_requests_API: 'Relatório das solicitações de requerimento via API',
          },
          settings: {
            title: 'Configurações',
            inform_tax: 'Informar taxas',
            calculation_standards: 'Padrões de cálculo',
            blocked_inscriptions: 'Inscrições bloqueadas',
            refis: 'REFIS',
            scheduling: 'Agendamento',
          },
          debt_box: 'Caixa de dívidas',
          financial: {
            title: 'Financeiro',
          },
        },
        search: {
          title: 'Busca',
          detran: 'Consulta DETRAN',
          to_parts: 'Busca documental (Beta)',
        },
        calendar: 'Calendário',
        support: {
          title: 'Suporte',
          suggestion_box: 'Caixa de sugestões',
          contact_us: 'Fale conosco',
          faq: 'FAQ (Dúvidas Frequentes)',
          settings_sistem: 'Configurações do sistema',
        },
        settings: {
          user_management: 'Gestão de usuários',
          fluxes_management: 'Gestão de fluxos',
        },
      },
    },

    detran: {
      title: 'Consulta DETRAN',
      inputText: 'CPF/CNPJ ou Placa do Veículo',
      search: 'Consultar',
      count: {
        singular: '{{count}} veículo encontrado',
        plural: '{{count}} veículos encontrados',
      },
      license_plate: 'Placa do Veículo:',
      name: 'Proprietário',
      address: 'Endereço',
      motor: 'Motor',
      renavam: 'Renavam',
      chassi: 'Chassi',
      color: 'Cor',
      type: 'Tipo',
      manufacture_year: 'Ano de Fabricação',
      model_year: 'Ano/Modelo',
      number_passengers: 'Qtde. de Passageiros',
      financial: 'Financeira',
      category: 'Categoria',
      species: 'Espécie',
      fuel: 'Combustível',
      car_body: 'Carroceria',
      model: 'Marca/Modelo',
      error: 'Erro ao pesquisar veículo',
    },

    modalAssociateCdas: {
      title: 'Associar CDA(s) ao processo',
    },

    modalResend: {
      title: 'Reenviar lançamento',
      cda_number: 'CDA: {{number}}',
      table: {
        type: 'Tipo de receita',
        administrative: 'Inscrição administrativa',
        mainValue: 'Valor principal',
        paidValue: 'Valor principal pago',
        newValue: 'Novo valor principal',
        sendDate: 'Data do envio',
      },

      submit: 'Reenviar lançamento',
    },

    models: {
      attachDocument: {
        enums: {
          type: {
            pja_administrative_process: 'Processo Administrativo',
            pja_diligence: 'Diligência',
            pja_legal_opinion: 'Parecer',
            pja_dispatch: 'Despacho',
            consultancy_diligence: 'Diligência',
            consultancy_legal_opinion: 'Parecer',
            consultancy_dispatch: 'Despacho',
            consultancy_letter: 'Ofício',
            consultancy_documentation: 'Documentação',
            prourma_deed: 'Escritura',
            prourma_registration: 'Matrícula',
            prourma_letter: 'Ofício',
            prourma_documentation: 'Documentação',
            prourma_shuttle: 'Traslado',
            cgeti_ci: 'CI',
            cgeti_reference_term: 'Termo de Referência',
            cgeti_proposal: 'Proposta',
            cgeti_quote_map: 'Mapa de Cotação',
            cgeti_technical_justification: 'Justificativa Técnica',
            cgeti_official_letter: 'Ofício',
            cgeti_official_letter_response: 'Resposta de Ofício',
            cgeti_price_registration_act: 'Ata de Registro de Preço',
            cgeti_notice: 'Edital',
            cgeti_publication: 'Publicação',
            cgeti_negative_certificate: 'Certidão Negativa',
            cgeti_legal_opinion: 'Parecer',
            cgeti_mapp_report: 'Relatório do MAPP',
            cgeti_documentation: 'Documentação',
            cgeti_draft_contract: 'Minuta de Contrato',
            cgeti_approval_term: 'Termo de Homologação',
            cgeti_economic_viability_justification: 'Justificativa de Economicidade',
            prodesp_basic_project: 'Projeto Básico',
            prodesp_rationale: 'Justificativa',
            prodesp_contingency_expense: 'Contingência de Despesa',
            prodesp_enabling_document: 'Documento de Habilitação',
            prodesp_price_quote: 'Cotação de Preço',
            prodesp_legal_opinion: 'Parecer',
            prodesp_administrative_agreement: 'Contrato Administrativo',
            prodesp_extract_publishing: 'Publicação do Extrato',
            prodesp_approval_terms_ratification: 'Termo de Homologação e Ratificação',
            prodesp_term_commitment: 'Termo de Empenho',
            prodesp_payment_voucher: 'Comprovante de Pagamento',
            requirement_analysis_deferment_opnion: 'Parecer de Deferimento',
            requirement_analysis_rejection_opnion: 'Parecer de Indeferimento',
            requirement_analysis_opnion_with_diligence: 'Parecer com Diligência',
            requirement_analysis_expedition_with_diligence: 'Despacho com Diligência',
            requirement_analysis_expedition: 'Despacho',
            requirement_analysis_documentation: 'Documentação',
            cabinet_administrative: 'Administrativa',
            cabinet_deed: 'Ato',
            cabinet_letter: 'Carta',
            cabinet_letter_rogatory: 'Carta Precatória',
            cabinet_active_debt_cert: 'Certidão de Dívida Ativa',
            cabinet_system_certificate: 'Certidão do Sistema',
            cabinet_internal_communication: 'Comunicações Internas',
            cabinet_paycheck: 'Contracheque',
            cabinet_social_contract: 'Contrato Social',
            cabinet_dispatch: 'Despacho',
            cabinet_documentation: 'Documentação',
            cabinet_personal_documents: 'Documentos Pessoais',
            cabinet_confidential_document: 'Documentos Sigilosos',
            cabinet_free_justice: 'Justiça Gratuita',
            cabinet_official_letter: 'Ofício',
            cabinet_legal_opinion: 'Parecer',
            cabinet_initial_document: 'Peça Inicial',
            cabinet_petition: 'Petição',
            cabinet_ordinance: 'Portaria',
            cabinet_attorney_document: 'Procuração/Substabelecimento',
            other: 'Outros',
            petition: 'Petição',
            attorney_document: 'Procuração/Substabelecimento',
            social_contract: 'Contrato Social',
            free_justice: 'Justiça Gratuita',
            letter: 'Carta',
            active_debt_cert: 'Certidão de Dívida Ativa',
            active_debt_confession: 'Termo de reconhecimento e confissão de dívida',
            initial_document: 'Peça Inicial',
            letter_rogatory: 'Carta Precatória',
            system_certificate: 'Certidão do Sistema',
            documentation: 'Documentação',
            personal_documant: 'Documentos Pessoais',
            confidential_document: 'Documentos Sigilosos',
            draft_decree: 'Minuta de decreto',
            draft_bill: 'Minuta de projeto de lei',
            administrative: 'Administrativa',
            report: 'Relatório',
            judicial_decision: 'Decisão Judicial',
            verdict: 'Sentença',
            judgment: 'Acórdão',
            conduct_adjustment_term: 'Termo de ajustamento de conduta',
            agreement: 'Acordo',
            notify: 'Notificação',
            term_of_commitment: 'Termo de compromisso',
            proof_protocol: 'Comprovante de Protocolo',
            draft: 'Minuta',
            report_to_management: 'Relatório para Chefia',
            financial_statement: 'Ficha Financeira',
            calculation_table: 'Tabela de Cálculos',
            null: 'Não informado',
          },
        },
      },
    },
  },
};
