import { t } from 'i18next';
import { useState } from 'react';
import { ReceiptTypeSearch } from '../../../../@types/activeDebt/debtBox';
import styles from './styles.module.scss';
import Format from '../../../../helpers/format';

type ReceiptTypesTabsProps = {
  receiptTypes: ReceiptTypeSearch[];
  loading?: boolean;
};

export function ReceiptTypesTabs(props: ReceiptTypesTabsProps) {
  const [selected, setSelected] = useState(props.receiptTypes[0]?.id);
  return (
    <div className={ styles.container }>
      {
        props.receiptTypes.map((rt) => (
          <a
            className={ `${styles.button} ${selected === rt.id ? styles.selected : ''}` }
            onClick={() => setSelected(rt.id)}
          >
            <span className={ styles.description }>
              {rt.description}
            </span>
            <span className={ styles.data }>
              {t('debtBox.search.receiptTypes.cdaAndRequeriments', { cdas: rt.total_cdas, requeriments: rt.total_requeriments })}
            </span>
            <span className={ styles.data }>
              {t('debtBox.search.receiptTypes.totalBalance', { balance: Format.currency(rt.total_balance) })}
            </span>
          </a>
        ))
      }
    </div>
  );
}
