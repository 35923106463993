import { Grid, Skeleton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ToastsList } from '../../../components/ToastsList';
import styles from './styles.module.scss';
import { Breadcumb } from '../../../components/Breadcumb';
import DebtBoxService from '../../../services/debtBoxService';
import { ContributorSearch, ReceiptTypeSearch } from '../../../@types/activeDebt/debtBox';
import { ContributorsTabs } from './ContributorsTabs';
import { SearchHeader } from './SearchHeader';
import { ReceiptTypesTabs } from './ReceiptTypesTabs';

export function DebtBoxSearch() {
  const [contributors, setContributors] = useState<ContributorSearch[]>([]);
  const [contributorSelected, setContributorSelected] = useState<number | undefined>();
  const [receiptTypes, setReceiptTypes] = useState<ReceiptTypeSearch[]>([]);
  const [loadingContributors, setLoadingContributors] = useState<boolean>(false);
  const [loadingReceiptTypes, setLoadingReceiptTypes] = useState<boolean>(false);
  const queryParams = new URLSearchParams(window.location.search);
  const search = queryParams.get('search');
  const { pathname } = useLocation();

  const searchObject = search ? JSON.parse(decodeURIComponent(search)) : null;

  const addQuery = (query: string, field: string) => {
    const result = query.length > 0 ? query.concat(`&${field}`) : query.concat(field);
    return result;
  };

  useEffect(() => {
    if (contributorSelected) {
      setLoadingReceiptTypes(true);
      setReceiptTypes([]);
      const contributor = contributors.find((c) => c.id === contributorSelected);
      if (contributor) {
        DebtBoxService.getReceiptTypesByContributor(contributor.id, contributor.inscriptions_ids)
          .then((res) => setReceiptTypes(res));
      }
    }
  }, [contributorSelected]);

  useEffect(() => {
    let query = '';
    if (searchObject.contributor) {
      query = addQuery(query, `q[contributor_id_eq]=${searchObject.contributor}`);
    }
    if (searchObject.processNumber) {
      query = addQuery(query, `q[judicial_process_number_eq]=${searchObject.processNumber}`);
    }
    if (searchObject.inscription) {
      query = addQuery(query, `q[searchObject.administrative_inscription_eq]=${searchObject.inscription}`);
    }
    if (searchObject.cda) {
      query = addQuery(query, `q[cda_number_eq]=${searchObject.cda}`);
    }
    if (searchObject.installmentNumber) {
      query = addQuery(query, `q[payment_request_number_eq]=${searchObject.installmentNumber}`);
    }
    if (searchObject.origin) {
      query = addQuery(query, `q[sefin_key_eq]=${searchObject.origin}`);
    }
    if (searchObject.showExtinctDebits) {
      query = addQuery(query, `q[show_extincts]=${searchObject.showExtinctDebits}`);
    }
    setLoadingContributors(true);
    DebtBoxService.getContributors(query).then((res) => {
      setLoadingContributors(false);
      setContributors(res);
    });
  }, []);

  return (
    <div className={styles.container}>
      <ToastsList />
      <Grid
        container
        columns={{ xs: 2, sm: 2, md: 12 }}
        spacing={2}
      >
        <Grid item xs={12} className={ styles.title }>
          <Grid item xs={3} sm={6}>
            <h1>{ t('debtBox.title') }</h1>
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
          </Grid>
        </Grid>
      </Grid>

      <div>
        <Grid item xs={12}>
          {
            <SearchHeader />
          }
        </Grid>
        <Grid item xs={12}>
          {
            <ContributorsTabs
              contributors={contributors}
              loading={loadingContributors}
              onChange={(id) => setContributorSelected(id)}
            />
          }
        </Grid>

        <Grid item xs={12}>
          {
            <ReceiptTypesTabs
              receiptTypes={receiptTypes}
              loading={loadingReceiptTypes}
            />
          }
        </Grid>

        <Grid item xs={12}>
          {
            <Skeleton height={1000} variant='rectangular'/>
          }
        </Grid>
      </div>
    </div>
  );
}
