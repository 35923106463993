import { Trans } from 'react-i18next';
import { t } from 'i18next';
import styles from './styles.module.scss';
import searchImg from '../../../../assets/searchImg.svg';

export function Instructions() {
  return (
    <div className={ styles.container }>
      <img src={searchImg} className={styles.img}/>

      <h1>
        <Trans
          i18nKey='debtBox.instructions.title'
          components={{ bold: <strong /> }} />
      </h1>

      <p>{t('debtBox.instructions.line01')}</p>

      <p>{t('debtBox.instructions.line02')}</p>

      <p>{t('debtBox.instructions.line03')}</p>

      <p>
        <Trans
          i18nKey='debtBox.instructions.link'
          components={{ a: <a /> }} />
      </p>
    </div>
  );
}
