export const TAGS = [
  {
    label: 'Endereço',
    fieldId: 'address',
    inputField: '',
    color: '#25757d',
  },
  {
    label: 'Multa autônoma',
    fieldId: 'address',
    inputField: '',
    color: '#25757d',
  },
  {
    label: 'Auto de infração',
    fieldId: 'address',
    inputField: '',
    color: '#ca9109',
  },
  {
    label: 'Declaração do contribuinte',
    fieldId: 'address',
    inputField: '',
    color: '#ca9109',
  },
  {
    label: 'Notificação',
    fieldId: 'address',
    inputField: '',
    color: '#ca9109',
  },
  {
    label: 'Outros processos adm.',
    fieldId: 'address',
    inputField: '',
    color: '#ca9109',
  },
  {
    label: 'Processo de parcelamento',
    fieldId: 'address',
    inputField: '',
    color: '#ca9109',
  },
  {
    label: 'Protestado',
    fieldId: 'address',
    inputField: '',
    color: '#c55c0d',
  },
  {
    label: 'Protesto cancelado',
    fieldId: 'address',
    inputField: '',
    color: '#c55c0d',
  },
  {
    label: 'Protesto sustado',
    fieldId: 'address',
    inputField: '',
    color: '#c55c0d',
  },
  {
    label: 'Negociação perdida',
    fieldId: 'address',
    inputField: '',
    color: '#b10633',
  },
  {
    label: 'Desjudicializado',
    fieldId: 'address',
    inputField: '',
    color: '#827d7d',
  },
  {
    label: 'Encaminhado para ajuizamento',
    fieldId: 'address',
    inputField: '',
    color: '#827d7d',
  },
  {
    label: 'Ajuizado',
    fieldId: 'address',
    inputField: '',
    color: '#827d7d',
  },
  {
    label: 'DAM gerado',
    fieldId: 'address',
    inputField: '',
    color: '#787e04',
  },
  {
    label: 'Boleto gerado',
    fieldId: 'address',
    inputField: '',
    color: '#787e04',
  },
  {
    label: 'Processo administrativo',
    fieldId: 'address',
    inputField: '',
    color: '#850ac1',
  },
  {
    label: 'Apto ao REFIS',
    fieldId: 'address',
    inputField: '',
    color: '#1a1818',
  },
];
